import React from "react";
import DecisionToggle from "../../../../components/badge/DecisionToggle";
import { ReactComponent as RedCross } from "../../../../assets/images/Red-Cross.svg";
import { ReactComponent as GrayCross } from "../../../../assets/images/Grey-Cross.svg";
import { useParams, useOutletContext,useNavigate } from "react-router-dom";


const UsernamePasswordTag = ({ username, password, Icon }) => (
  <div className="d-flex gap-2">
    <DecisionToggle
      label={
        <div className="d-flex align-items-center">
          <Icon className='me-1' />
          <div>Username: {username}</div>
        </div>
      }
      isIndication={false}
    />
    <DecisionToggle
      label={
        <div className="d-flex align-items-center">
          <Icon className='me-1' />
          <div>Password: {password}</div>
        </div>
      }
      isIndication={false}
    />
  </div>
);

const FTPDefaultCredentials = ({ row }) => {
  const credentials = [
    { username: row.username, password: row.password },
    { username: row.username2, password: row.password2 },
    { username: row.username3, password: row.password3 },
  ].filter(cred => cred.username);
  const routeParams = useParams();
  const navigate = useNavigate();
  const handleNavigation = () => {
    if (!row.is_encrypted && credentials.length > 0) {
      navigate(`/security-issues/${routeParams?.target_id}?ip=${row.ip}&armoryIdParam=arm-ftp3`);
    } else if (row.is_encrypted && credentials.length > 0) {
      navigate(`/security-issues/${routeParams?.target_id}?ip=${row.ip}&armoryIdParam=arm-ftp1`);
    }
  };

  return (
    <div onClick={handleNavigation}>
      {credentials.length > 0 ? (
        <div className="flex gap-1 flex-col custom-ssh-badge-h">
          {credentials.map((cred) => (
            <div key={cred.id} className="mt-1">
              <UsernamePasswordTag
                username={cred.username}
                password={cred.password}
                Icon={RedCross}
              />
            </div>
          ))}
        </div>
      ) : (
        <DecisionToggle
          label={
            <div className="d-flex align-items-center">
              <GrayCross className='me-1' />
              <div>Not Vulnerable</div>
            </div>
          }
          isIndication={true}
        />
      )}
    </div>
  );
};

export default FTPDefaultCredentials;
