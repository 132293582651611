import React, { useState, useEffect, useRef, useContext } from "react";
import BaseTable from "../../components/table/BaseTable";
import BaseFilter from "../../components/filter/BaseFilter";
import DomainTree from "./DomainTreeMap";
import axios from "../../util/axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ActionConfirmationModal from "../../components/actions/ActionConfirmationModal.jsx";
import DomainListTable from "./DomainListTable.js";
import { ScannerContext } from "../../components/ScannerContext";
import { formatScannerName } from "../../helpers/formatScannerName.js";
import moment from "moment";
import { locationToPageName } from "../../helpers/index.js";
import { sendEvent } from "../../util/analytics.js";
import AssetsDiscovery from "./AssetsDiscovery";

const DomainList = ({
  webScanner,
  setScanStatus,
  onStatsFetch,
  setIsLoading,
  setTotalActive,
  setTotalActiveHost,
  isActiveLink
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [initialDataFetched, setInitialDataFetched] = useState(false);
  const [activeView, setActiveView] = useState("List");
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isTarget, setIsTarget] = useState(false);
  const [filteredTargets, setFilteredTargets] = useState([]);
  const [targetScan, setTargetScan] = useState({});
  const [csvRows, setCsvRows] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [rejectIP, setRejectIP] = useState([]);
  const [isScannerList, setIsScannerList] = useState(false);
  const [servicesStatus, setServicesSatus] = useState(null);
  const [totalDomain, setTotalDomain] = useState(0);
  const [currentPage, setCurrentPage] = useState({});


  const [discoveryData, setDiscoveryData] = useState([]);
  const [assetsStatus, setAssetsStatus] = useState(null)
  const [mapData, setMapData] = useState({});

  const isFirstRunRef = useRef(true);
  const queueRef = useRef([]);
  const isProcessingRef = useRef(false);
  const abortControllerRef = useRef(null);
  const timeoutRef = useRef(null);
  const currentScanIdRef = useRef(id);
  const isFirstFetch = useRef(true);
  const { dropdownItems } = useContext(ScannerContext);
  const [openAccordian, setOpenAccordian] = useState(false);
  const logColumns = [
    { Header: "Domain", accessor: "domain", isSortable: false },
    { Header: "Ip Address", accessor: "ip", isSortable: false },
    {
      Header: "Host Name",
      isSortable: false,
      accessor: () => (
        <div className="greentext">Initializing first-time scanning...</div>
      ),
    },
    { Header: "Web Server", accessor: "web", isSortable: false },
  ];

  const [showModal, setShowModal] = useState(false);
  const [tabs, setTabs] = useState([
    { eventKey: "1", title: "Domain", contentTitle: "Domain", data: [] },
    { eventKey: "3", title: "Hostname", contentTitle: "Hostname", data: [] },
    {
      eventKey: "2",
      title: "IP Address",
      contentTitle: "IP Address",
      data: [],
    },
    { eventKey: "5", title: "Status", contentTitle: "Status", data: [] },
    {
      eventKey: "4",
      title: "Hosting Provider",
      contentTitle: "Hosting Provider",
      data: [],
    },
    {
      eventKey: "6",
      title: "CDN",
      contentTitle: "CDN",
      data: [],
    },
    {
      eventKey: "7",
      title: "Ports",
      contentTitle: "Ports",
      data: [],
    },
  ]);

  const actions = [{ id: 1, name: "Reject IP", checked: false }];
  const [data, setData] = useState([]);
  const [targets, setTargets] = useState([]);
  const [listLogs, setListLogs] = useState([]);

  const statusFilterOptions = [
    {
      id: 0,
      name: "Validating",
      key: "5",
      active: false,
    },
    {
      id: 1,
      name: "Active",
      key: "5",
      active: false,
    },
    {
      id: 2,
      name: "Inactive",
      key: "5",
      active: false,
    },
  ];
  const transformData = (rawData) => {
    return rawData.ips.map((ip) => {
      const hostInfo = rawData.scanInfo.scan_target_hosts.find(
        (host) => host.id === ip.ip.domain_id
      );
      let statusValue = "";
      if (!ip?.is_processed) {
        statusValue = "Validating";
      } else {
        if (ip?.status === 1) {
          statusValue = "Active";
        } else {
          statusValue = "Inactive";
        }
      }
      return {
        id: ip.id,
        domain: hostInfo ? hostInfo.host : "",
        isSortable: true,
        ip: ip.ip,
        is_processed: ip.is_processed,
        status: ip.status,
        ip_id: ip.ip_id,
        ipData: ip.ip.ip,
        scan_hosts: ip.scan_hosts.map((scanHost) => scanHost),
        web_ports: ip.web_ports,
        cdn_name: ip.cdn_name,
        hosting_provider: ip.hosting_provider,
        scan_ports: ip.scan_ports,
        domain_id: ip?.ip?.domain_id,
        number_issues: ip?.number_issues,
        statusValue,
        targetUID: rawData?.targetUID || null,
      };
    });
  };

  const appendDataOneByOne = async (newItems) => {
    if (isFirstRunRef.current) {
      isFirstRunRef.current = false;
    }
    const modifiedData = transformData(newItems);
    for (let i = 0; i < modifiedData.length; i++) {
      if (currentScanIdRef.current !== id) {
        return;
      }
      const newItem = modifiedData[i];
      setFilteredTargets((prevData) => {
        const existingIndex = prevData.findIndex(
          (item) => item.id === newItem.id
        );
        if (existingIndex !== -1) {
          const updatedData = [...prevData];
          updatedData[existingIndex] = newItem;
          return updatedData;
        } else {
          return [...prevData, newItem];
        }
      });
      await new Promise((resolve) => setTimeout(resolve, 500));
    }
  };

  const processQueue = async () => {
    while (queueRef.current.length > 0) {
      if (currentScanIdRef.current !== id) {
        return; // Exit if the current scan ID has changed
      }
      const nextBatch = queueRef.current.shift();
      await appendDataOneByOne(nextBatch);
    }
    isProcessingRef.current = false;
  };

  const updateTabsWithData = (assets) => {
    const domains = Array.from(
      new Set(assets.scanInfo.hosts.map((host) => host.host))
    ).map((host, index) => ({
      id: index,
      name: host,
      type: "Domain: " + host,
      key: "1",
      active: false,
    }));
    const ips = Array.from(new Set(assets.ips.map((ip) => ip.ip))).map(
      (ip, index) => ({
        id: index,
        name: ip.ip,
        type: "IP Address: " + ip?.ip,
        key: "2",
        active: false,
      })
    );
    const hostingProvider = Array.from(
      new Set(
        assets.ips
          .map((server) => server.hosting_provider)
          ?.filter((provider) => provider)
      )
    ).map((server, index) => ({
      id: index,
      name: server,
      type: "Hosting Provider: " + server,
      key: "4",
      active: false,
    }));
    const cdn = Array.from(
      new Set(
        assets.ips
          .map((server) => server.cdn_name)
          ?.filter((provider) => provider)
      )
    ).map((server, index) => ({
      id: index,
      name: server,
      type: "CDN: " + server,
      key: "6",
      active: false,
    }));
    const hosts = Array.from(
      new Set(
        assets.ips.flatMap((host) =>
          host.scan_hosts.map((scanHost) => scanHost.host.host)
        )
      )
    ).map((server, index) => ({
      id: index,
      name: server,
      type: "Hostname: " + server,
      key: "3",
      active: false,
    }));
    const ports = Array.from(
      new Set(
        assets.ips.flatMap((host) =>
          host.scan_ports.map((scanHost) => `${scanHost.port}`)
        )
      )
    ).map((server, index) => ({
      id: index,
      name: server,
      type: "Port: " + server,
      key: "7",
      active: false,
    }));
    setTabs([
      {
        eventKey: "1",
        title: "Domain",
        contentTitle: "Domain",
        data: Array.from(domains),
      },
      {
        eventKey: "3",
        title: "Hostname",
        contentTitle: "Hostname",
        data: Array.from(hosts),
      },
      {
        eventKey: "2",
        title: "IP Address",
        contentTitle: "IP Address",
        data: Array.from(ips),
      },
      {
        eventKey: "5",
        title: "Status",
        contentTitle: "Status",
        data: statusFilterOptions,
      },
      {
        eventKey: "4",
        title: "Hosting Provider",
        contentTitle: "Hosting Provider",
        data: Array.from(hostingProvider),
      },
      {
        eventKey: "6",
        title: "CDN",
        contentTitle: "CDN",
        data: Array.from(cdn),
      },
      {
        eventKey: "7",
        title: "Ports",
        contentTitle: "Ports",
        data: Array.from(ports),
      },
    ]);
  };

  const listScanLogs = (data) => {
    const logs = data.map((host) => ({
      domain: host.host.match(/\b(?:\d{1,3}\.){3}\d{1,3}\b/) ? "" : host.host,
      ip: host.host.match(/\b(?:\d{1,3}\.){3}\d{1,3}\b/) ? host.host : "",
      host: host.host, // assuming you want to display the host in all cases
      web: "",
    }));
    setListLogs(logs);
  };

  const generateWebServerLinks = (ipData) => {
    const host =
      ipData.scan_hosts.length > 0
        ? ipData.scan_hosts[0].host.host
        : ipData.ip.ip;
    let httpLink = "";
    let httpsLink = "";
    if (ipData.web_ports) {
      httpLink = ipData.web_ports.includes("80") ? `http://${host}` : "";
      httpsLink = ipData.web_ports.includes("443") ? `https://${host}` : "";
    }
    return `${httpLink}${httpLink && httpsLink ? " | " : ""}${httpsLink}`;
  };

  const fetchScans = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for the new request
    const controller = new AbortController();
    abortControllerRef.current = controller;
    try {
      let scanId = id;
      if (!scanId && targets.length > 0) {
        scanId = targets[0].id;
      }

      if (scanId) {
        const { data } = await axios.get(`/scans/${scanId}`, {
          signal: controller.signal,
        });
        const service = data?.scanInfo?.services.find(
          (service) => service.service_id
        );
        setServicesSatus(service?.service_status);

        if (
          isFirstFetch.current ||
          service?.service_status === 3 ||
          service?.service_status === -1
        ) {
          const modifiedData = data.ips.map((ip) => {
            const hostInfo = data.scanInfo.scan_target_hosts.find(
              (host) => host.id === ip.ip.domain_id
            );
            let statusValue = "";
            if (!ip?.is_processed) {
              statusValue = "Validating";
            } else {
              if (ip?.status) {
                statusValue = "Active";
              } else {
                statusValue = "Inactive";
              }
            }
            return {
              ...ip,
              id: ip.id,
              domain: hostInfo ? hostInfo.host : "",
              isSortable: true,
              ip: ip.ip,
              is_processed: ip.is_processed,
              status: ip.status,
              ip_id: ip.ip_id,
              ipData: ip.ip.ip,
              scan_hosts: ip.scan_hosts.map((scanHost) => scanHost),
              web_ports: ip.web_ports,
              cdn_name: ip.cdn_name,
              hosting_provider: ip.hosting_provider,
              scan_ports: ip.scan_ports,
              domain_id: ip?.ip?.domain_id,
              number_issues: ip?.number_issues,
              statusValue,
              targetUID: data?.target_uuid || null,
              created_at: ip.ip?.created_at,
            };
          });
          const discoveryFormattedData = [];
          modifiedData?.map((el, index) => {
            let data = {
              domain: el?.domain,
              ip: el.ip?.ip,
              is_processed: el.is_processed,
              cdn_name: el.cdn_name,
              hosting_provider: el.hosting_provider,
              number_issues: el?.number_issues,
              statusValue: el?.statusValue,
              status: el.status,
              scan_ports: el.scan_ports,
              ip_id: el.ip_id,
              domain_id: el.domain_id,
              change_status: el?.is_removed ? 3 : el?.change_status,
            };
            if (el?.scan_hosts?.length > 0) {
              el?.scan_hosts?.map((ol, ind) => {
                let changes = [];
                if (el?.hosting_changes?.length > 0) {
                  el?.hosting_changes?.map((change) => changes.push(change));
                }
                if (ol?.cdn_changes?.length > 0) {
                  ol?.cdn_changes?.map((change) => changes.push(change));
                }
                if (ol?.cms_changes?.length > 0) {
                  ol?.cms_changes?.map((change) => changes.push(change));
                }
                el?.scan_ports?.map((ports) => {
                  if (ports?.changes?.length > 0) {
                    ports?.changes?.map((change) => changes.push(change));
                  }
                });
                discoveryFormattedData?.push({
                  ...data,
                  id: Number(`${index + 1}${ind + 1}`),
                  host_id: ol.host_id,
                  host: ol?.host?.host,
                  first_detected: ol?.host?.created_at,
                  discovered_by: ol?.discovered_by,
                  changes,
                });
              });
            } else {
              let changes = [];
              if (el?.hosting_changes?.length > 0) {
                el?.hosting_changes?.map((change) => changes.push(change));
              }
              el?.scan_ports?.map((ports) => {
                if (ports?.changes?.length > 0) {
                  ports?.changes?.map((change) => changes.push(change));
                }
              });
              discoveryFormattedData.push({
                ...data,
                id: index + 1,
                host_id: null,
                discovered_by: el?.discovered_by,
                first_detected: el?.created_at,
                host: "",
                changes,
              });
            }
          });
          setAssetsStatus(data?.groups?.changes);
          setDiscoveryData(discoveryFormattedData);
          setFilteredTargets(modifiedData);
          if (isFirstFetch.current) {
            isFirstFetch.current = false;
          }
        } else {
          const newIps = {
            ips: data?.ips || [],
            scanInfo: data?.scanInfo || {},
            targetUID: data?.target_uuid || null,
          };
          const modifiedData = data.ips.map((ip) => {
            const hostInfo = data.scanInfo.scan_target_hosts.find(
              (host) => host.id === ip.ip.domain_id
            );
            let statusValue = "";
            if (!ip?.is_processed) {
              statusValue = "Validating";
            } else {
              if (ip?.status) {
                statusValue = "Active";
              } else {
                statusValue = "Inactive";
              }
            }
            return {
              ...ip,
              id: ip.id,
              domain: hostInfo ? hostInfo.host : "",
              isSortable: true,
              ip: ip.ip,
              is_processed: ip.is_processed,
              status: ip.status,
              ip_id: ip.ip_id,
              ipData: ip.ip.ip,
              scan_hosts: ip.scan_hosts.map((scanHost) => scanHost),
              web_ports: ip.web_ports,
              cdn_name: ip.cdn_name,
              hosting_provider: ip.hosting_provider,
              scan_ports: ip.scan_ports,
              domain_id: ip?.ip?.domain_id,
              number_issues: ip?.number_issues,
              statusValue,
              targetUID: data?.target_uuid || null,
              created_at: ip.ip?.created_at,
            };
          });
          const discoveryFormattedData = [];
          modifiedData?.map((el, index) => {
            let data = {
              domain: el?.domain,
              ip: el.ip?.ip,
              is_processed: el.is_processed,
              cdn_name: el.cdn_name,
              hosting_provider: el.hosting_provider,
              number_issues: el?.number_issues,
              statusValue: el?.statusValue,
              status: el.status,
              scan_ports: el.scan_ports,
              ip_id: el.ip_id,
              domain_id: el.domain_id,
              change_status: el?.is_removed ? 3 : el?.change_status,
            };
            if (el?.scan_hosts?.length > 0) {
              el?.scan_hosts?.map((ol, ind) => {
                let changes = [];
                if (el?.hosting_changes?.length > 0) {
                  el?.hosting_changes?.map((change) => changes.push(change));
                }
                if (ol?.cdn_changes?.length > 0) {
                  ol?.cdn_changes?.map((change) => changes.push(change));
                }
                if (ol?.cms_changes?.length > 0) {
                  ol?.cms_changes?.map((change) => changes.push(change));
                }
                el?.scan_ports?.map((ports) => {
                  if (ports?.changes?.length > 0) {
                    ports?.changes?.map((change) => changes.push(change));
                  }
                });
                discoveryFormattedData?.push({
                  ...data,
                  id: Number(`${index + 1}${ind + 1}`),
                  host_id: ol.host_id,
                  host: ol?.host?.host,
                  first_detected: ol?.host?.created_at,
                  discovered_by: ol?.discovered_by,
                  changes,
                });
              });
            } else {
              let changes = [];
              if (el?.hosting_changes?.length > 0) {
                el?.hosting_changes?.map((change) => changes.push(change));
              }
              el?.scan_ports?.map((ports) => {
                if (ports?.changes?.length > 0) {
                  ports?.changes?.map((change) => changes.push(change));
                }
              });
              discoveryFormattedData.push({
                ...data,
                id: index + 1,
                host_id: null,
                discovered_by: el?.discovered_by,
                first_detected: el?.created_at,
                host: "",
                changes,
              });
            }
          });
          setAssetsStatus(data?.groups?.changes);
          setDiscoveryData(discoveryFormattedData);
          queueRef.current.push(newIps);
          if (!isProcessingRef.current) {
            isProcessingRef.current = true;
            processQueue();
          }
        }

        if (data?.scanInfo?.services[0].service_status === 3) {
          clearTimeout(timeoutRef.current);
        }
        if (!data?.ips || data.ips.length === 0) {
          listScanLogs(data?.scanInfo?.scan_target_hosts);
          setIsScannerList(true);
        } else {
          setIsScannerList(false);

          setScanStatus(data?.scanInfo?.services[0].service_status);
          if (service?.service_status === 3 || service?.service_status === -1) {
            setTimeout(() => {
              clearTimeout(timeoutRef.current);
              console.log("Stopping fetch due to service status 3 or -1");
            }, 5000);
          }
        }
        if (data?.scanInfo) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = setTimeout(fetchScans, 5000);
        } else {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = setTimeout(fetchScans, 2000);
        }
        webScanner(data);
        let totalHostnames = 0;
        let webServersCount = 0;

        data?.ips?.forEach((ipItem) => {
          if (ipItem.scan_hosts && Array.isArray(ipItem.scan_hosts)) {
            totalHostnames += ipItem.scan_hosts.length;
          }
          if (ipItem.web_ports && ipItem.web_ports.length > 0) {
            webServersCount += 1;
          }
        });

        onStatsFetch({
          domains: data.totals.domain,
          ips: data.totals.ips,
          hosts: totalHostnames,
          webServers: webServersCount,
          totalIssues: data.scanInfo?.totalIssues,
          new: 0,
          removed: 0,
        });
        setTotalDomain(data?.totals?.domain);
        setData(data.ips);
        setMapData(data);
        updateTabsWithData(data);
        setCsvRows(
          data?.ips.map((ipData) => {
            const domain =
              data.scanInfo.hosts.find(
                (host) => host.key === ipData.ip.domain_id
              )?.host || "";
            const ipAddress = ipData.ip?.ip || "";
            const webServerLinks = generateWebServerLinks(ipData);
            const hosts = ipData.scan_hosts
              .map((hostData) => hostData.host.host)
              .join(" | ");
            return [domain, ipAddress, hosts, webServerLinks];
          })
        );
        let totalActive = 0;
        let totalActiveHost = 0;
        data.ips.forEach((ip) => {
          if (ip?.is_processed && ip.status === 1) {
            totalActive++;
            totalActiveHost += ip.scan_hosts.length;
          }
        });
        setTotalActive(totalActive);
        setTotalActiveHost(totalActiveHost);

        setLoading(false);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setLoading(false);
      }
      if (!initialDataFetched) {
        setInitialDataFetched(true);
      }
    } catch (err) {
      if (err?.response?.data?.message === "Unable to find the scan!") {
        navigate(-1);
      } else {
        setScanStatus(false);
        onStatsFetch({});
        setFilteredTargets([]);
        setData([]);
        setMapData([]);
        setCsvRows([]);
        if (!controller.signal?.aborted) {
          setIsLoading(false);
        }
        setLoading(false);
        setTotalDomain(0);
      }
    }
  };

  const getExportedRows = (dataForExport) => {
    const result = dataForExport.map((item) => {
      return item; // Keep the domain name for all rows
    });

    const body = result.flatMap((ipData) => {
      const domain = ipData?.domain || "";
      const ipAddress = ipData.ip?.ip || "";
      const statusValue = !ipData?.is_processed
        ? "Validating"
        : ipData?.status
          ? "Active"
          : "Inactive";
      const hostingProvider = ipData?.hosting_provider || "";
      const cdnName = ipData?.cdn_name || "";
      const ports = `"${ipData.scan_ports
        .map((hostData) => hostData?.port)
        .join(", ")}"`;

      return ipData.scan_hosts.map((hostData) => {
        const host = hostData?.host.host || "";
        return [
          domain,
          host,
          ipAddress,
          statusValue,
          hostingProvider,
          cdnName,
          ports,
        ];
      });
    });

    return body;
  };

  const fetchTargets = async () => {
    setIsTarget(true);
    try {
      const { data } = await axios.get(`targets`);
      setTargets(data.items);
      setIsTarget(false);
      if (data.items.length <= 0) {
        navigate("/assets-inventory/add");
      }
    } catch (err) { }
  };

  const init = async () => {
    await fetchTargets();
    setIsLoading(true);
    setLoading(true);
    fetchScans();
    setTotalActive(0);
    setTotalActiveHost(0);
  };

  useEffect(() => {
    const targetScan = dropdownItems.filter((item) => {
      return item.target_uuid === id;
    })[0];
    setTargetScan(targetScan);
  }, [targets]);

  useEffect(() => {
    if (id) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      queueRef.current = [];
      isProcessingRef.current = false;
      setFilteredTargets([]);
      currentScanIdRef.current = id;
      isFirstFetch.current = true;
      isFirstRunRef.current = true;
      init();
      return () => {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        clearTimeout(timeoutRef.current);
      };
    }
  }, [id]);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    resetCurrentPage();
    setOpenAccordian(true);
  };

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
    resetCurrentPage();
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };

  const resetCurrentPage = () => {
    const resetPages = Object.keys(currentPage).reduce((acc, key) => {
      acc[key] = 1;
      return acc;
    }, {});
    setCurrentPage(resetPages);
  };

  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});

  const filteredData = filteredTargets.filter((target) => {
    // Check if item matches all groups of filters (intersection)
    const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
      // Check if item matches at least one filter in the current group (union)
      return groupedFilters[eventKey].some((filter) => {
        const lowerCaseFilter = filter.name.toLowerCase();
        if (eventKey === "1") {
          return target.domain.toLowerCase() === lowerCaseFilter;
        } else if (eventKey === "2") {
          return (
            target.ip &&
            target.ip.ip &&
            target.ip.ip.toLowerCase() === lowerCaseFilter
          );
        } else if (eventKey === "3") {
          return (
            target.scan_hosts &&
            target.scan_hosts.some(
              (host) =>
                host.host &&
                typeof host.host === "object" &&
                host.host.host &&
                typeof host.host.host === "string" &&
                host.host.host.toLowerCase() === lowerCaseFilter
            )
          );
        } else if (eventKey === "4") {
          return (
            target.hosting_provider &&
            target.hosting_provider.toLowerCase() === lowerCaseFilter
          );
        } else if (eventKey === "5") {
          return (
            (lowerCaseFilter === "validating" && !target.is_processed) ||
            (lowerCaseFilter === "active" &&
              target.is_processed &&
              target.status === 1) ||
            (lowerCaseFilter === "inactive" &&
              target.is_processed &&
              !target.status)
          );
        } else if (eventKey === "6") {
          return (
            target.cdn_name && target.cdn_name.toLowerCase() === lowerCaseFilter
          );
        } else if (eventKey === "7") {
          return (
            target.scan_ports &&
            target.scan_ports.some(
              (port) =>
                port?.port && `${port.port}`.toLowerCase() === lowerCaseFilter
            )
          );
        }
        return false;
      });
    });

    const matchesSearch =
      searchValue === "" ||
      target.domain.toLowerCase().includes(searchValue.toLowerCase()) ||
      (target.ip &&
        target.ip.ip &&
        typeof target.ip.ip === "string" &&
        target.ip.ip.toLowerCase().includes(searchValue.toLowerCase())) ||
      (target.scan_hosts &&
        target.scan_hosts.some(
          (host) =>
            host.host &&
            typeof host.host === "object" &&
            host.host.host &&
            typeof host.host.host === "string" &&
            host.host.host.toLowerCase().includes(searchValue.toLowerCase())
        )) ||
      (target.web_ports &&
        target.web_ports.toLowerCase().includes(searchValue.toLowerCase()));

    return matchesFilters && matchesSearch;
  });

  const onRowSelect = (selectedRow) => {
    const selectedIpsToAdd = [];
    const selectedIpsToRemove = [];
    Object.entries(selectedRow).forEach(([rowId, isSelected]) => {
      const selectedData = filteredData.find((row) => row.id === Number(rowId));
      if (selectedData) {
        const ipData = {
          domain_id: selectedData?.ip?.domain_id,
          ip_id: selectedData?.ip_id,
          ip: selectedData?.ip?.ip,
        };
        if (isSelected) {
          selectedIpsToAdd.push(ipData);
        } else {
          selectedIpsToRemove.push(ipData);
        }
      }
    });

    setRejectIP((prevIps) => {
      const updatedIps = prevIps.filter(
        (ip) =>
          !selectedIpsToRemove.some(
            (selectedIp) => selectedIp.ip_id === ip.ip_id
          )
      );
      return [...updatedIps, ...selectedIpsToAdd];
    });
    setRejectIP(selectedIpsToAdd);
  };
  const handleApplyActions = (data) => {
    const ips = [];
    ips.push({
      domain_id: data.data?.domain_id,
      ip_id: data.data?.ip_id,
      ip: data?.data?.ip,
    });
    setRejectIP(ips);
    setShowModal(true);
  };

  const location = useLocation();
  const handleRejectSubmit = () => {
    axios
      .post(`/scans/${id}/rejects`, rejectIP)
      .then((response) => {
        toast.success(response.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "info",
        });
        const pageName = locationToPageName(location);
        sendEvent("buttonClick", {
          pageName,
          result: "rejectIP",
          ip: rejectIP,
        });
        setShowModal(false);
        fetchScans();
      })
      .catch((e) => { });
  };
  return (
    <>
      {
        isActiveLink === "Overview" ?
          <div className="my-3 list_header">
            <BaseFilter
              tabs={tabs}
              onFilterChangeWaf={handleFilterChange}
              activeFilters={activeFilters}
              removeFilter={removeFilter}
              iskeyfilter={true}
              totalRecords={totalDomain}
              exportTitle={`AssetInventory_${formatScannerName(
                targetScan?.title
              )}-${moment().format("DDMMMYYYY").toUpperCase()}`}
              exportHeader={[
                "Domain",
                "Hostname",
                "IP Address",
                "Status",
                "Hosting Provider",
                "CDN",
                "Port",
              ]}
              exportRows={getExportedRows(filteredData)}
              activeRows={rejectIP}
              handleApplyActions={() => setShowModal(true)}
              searchValue={searchValue}
              onSearchChange={handleSearchChange}
              isSearch={true}
            />
            {showModal && (
              <ActionConfirmationModal
                showModal={showModal}
                onHide={() => setShowModal(false)}
                onReject={handleRejectSubmit}
                data={rejectIP}
              />
            )}

            {activeView === "List" && (
              <>
                {isScannerList ? (
                  <BaseTable
                    columns={logColumns}
                    data={listLogs}
                    selectable={false}
                    showCheckboxes={false}
                    action={false}
                  />
                ) : (
                  <DomainListTable
                    domains={filteredData}
                    setTotalActive={setTotalActive}
                    servicesStatus={mapData.servicesStatus}
                    setTotalActiveHost={setTotalActiveHost}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    handleApplyActions={handleApplyActions}
                    onRowSelect={onRowSelect}
                    accordianEnabled={openAccordian}
                  />
                )}
              </>
            )}
            {activeView === "Map" && (
              <>
                <DomainTree
                  title={targetScan?.title}
                  targets={mapData}
                  filteredData={filteredData}
                  loading={isScannerList}
                />
              </>
            )}
          </div>
          :
          <AssetsDiscovery
            data={discoveryData}
            servicesStatus={mapData.servicesStatus}
            updateDiscoveryData={(newData) => {
              setDiscoveryData(newData);
            }}
            assetsStatus={assetsStatus}
          />
      }
    </>
  );
};

export default DomainList;
