import React, { useState } from "react";
import { ReactComponent as RightChevron } from "../../assets/images/chevron-right.svg";
import { Button } from "react-bootstrap";
import NewsBadges from "./NewsBadges";
import "./NewsBadges.scss";
import moment from "moment";
import { Link } from "react-router-dom";

export const UpdateList = ({
  badges,
  date,
  title,
  subUpdates,
  image,
  url,
  visit_page,
  preview,
}) => {
  const [collapse, setCollapse] = useState(true);

  return (
    <div style={{ border: "1px solid #ffffff", borderRadius: "4px" }}>
      <div
        className="update-list cursor-pointer w-100"
        onClick={() => {
          setCollapse(!collapse);
        }}
      >
        <div className="flex gap-2 items-center w-100">
          <RightChevron />

          <div className="w-100">
            <div className="sub-content">
              <div className="flex col-12 content-link">
                <div className="flex items-center gap-3 col-9">
                  <div>
                    <span style={{ fontSize: "12px" }}>
                      {moment(date).format("MMMM DD,YYYY")}
                    </span>
                    <h4 className="m-0">{title}</h4>
                  </div>
                  <div className="divider"></div>
                  {badges.map((item) => (
                    <div className="flex gap-2 align-items-center">
                      <NewsBadges category={item} />
                    </div>
                  ))}
                </div>
                <div>
                  {visit_page ? (
                    <Link className="visit-link m-0" to={url}>
                      Visit Screen
                    </Link>
                  ) : null}
                </div>
              </div>
              {image ? (
                <img
                  src={image}
                  alt=""
                  className={preview ? "" : "col-9"}
                  style={
                    preview
                      ? {
                          height: "auto",
                          width: "500px",
                          objectFit: "contain",
                        }
                      : {}
                  }
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {!collapse && subUpdates.length ? (
        <>
          <div className="new-details">
            <ul className="flex flex-col gap-3">
              {subUpdates.map((update) => (
                <>
                  <li>
                    <div className="sub-content">
                      <div className="flex col-12 content-link">
                        <div className="flex items-center gap-3 col-9">
                          <h4 className="m-0">{update.title}</h4>
                          <div className="divider"></div>
                          {update.badges.map((item) => (
                            <div className="flex gap-2 align-items-center">
                              <NewsBadges category={item} />
                            </div>
                          ))}
                        </div>
                        {update.visit_page ? (
                          <Link className="text-link m-0 " to={update.url}>
                            Visit Screen
                          </Link>
                        ) : null}
                      </div>
                      {update.image ? (
                        <img
                          src={update.image}
                          alt=""
                          className={preview ? "" : "col-9"}
                          style={
                            preview
                              ? {
                                  height: "auto",
                                  width: "500px",
                                  objectFit: "contain",
                                }
                              : {}
                          }
                        />
                      ) : null}
                    </div>
                  </li>
                  {subUpdates.length > 1 ? <hr /> : null}
                </>
              ))}
            </ul>
          </div>
        </>
      ) : null}
    </div>
  );
};
