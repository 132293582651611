import React, { useState, useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Image, Table, Button, FormControl } from "react-bootstrap";
import { Triangle, ThreeCircles } from "react-loader-spinner";
import { ReactComponent as DeleteIcon } from "bootstrap-icons/icons/trash.svg";
import WhatsNewCreate from "./WhatsNewCreate";
import { Modal } from "react-bootstrap";
import { ReactComponent as Edit } from "../../../assets/images/edit.svg";

const WhatsNewListing = () => {
  const [editData, setEditData] = useState({});
  const [deletingId, setDeletingId] = useState(null);
  const [showEditModal, setEditModal] = useState(false);
  const badges = [
    { name: "Bugs Fix", value: "bug" },
    { name: "UI Update", value: "ui" },
    { name: "Integration Update", value: "integration" },
    { name: "New Feature", value: "feature" },
    { name: "None", value: "none" },
  ];

  const handleEditClick = (data) => {
    setEditData(data);
    setEditModal(true);
  };

  const handleEditClose = () => {
    setEditModal(false);
  };

  const exampleData = [
    {
      id: 1,
      title: "UI Update",
      badge: ["ui"],
      visit_page: true,
      file: null,
      url: "",
    },
    {
      id: 2,
      title: "Bug Fix",
      badge: ["bug"],
      visit_page: false,
      file: null,
      url: "",
    },
  ];

  const handleDelete = (id) => {};

  const memoizedBadgeNames = useMemo(() => {
    return exampleData.map((item) => ({
      ...item,
      badgeNames: item.badge
        .map(
          (badgeValue) =>
            badges.find((badge) => badge.value === badgeValue)?.name
        )
        .join(", "),
    }));
  }, [exampleData, badges]);

  return (
    <>
      <React.Fragment>
        <Container fluid className="main_container_style">
          <Row className="justify-content-start m-0">
            <Col>
              <div className="d-flex align-items-center justify-content-between">
                {" "}
                <h1 className="fw-normal mb-3">What's New</h1>
                <Link
                  to="/admin/whats-new/create"
                  className="add-button header-title"
                >
                  <i className="bi bi-plus-circle mx-2"></i>Add New
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table variant="dark" striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Category</th>
                    <th>Visit Page</th>
                    <th>URL</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {memoizedBadgeNames.map((item) => (
                    <tr key={item.id}>
                      <td>{item.title}</td>
                      <td>{item.badgeNames}</td>
                      <td>{item.visit_page ? "Yes" : "No"}</td>
                      <td>{item.url}</td>
                      <td>
                        <div className="flex align-items-center gap-2">
                          <Edit
                            className="cursor-pointer"
                            onClick={() => handleEditClick(item)}
                          />
                          {deletingId === item.id ? (
                            <div key="loader" className="loader">
                              <ThreeCircles
                                visible={true}
                                height="30"
                                width="30"
                                color="#017df9"
                                ariaLabel="three-circles-loading"
                                wrapperClass=""
                              />
                            </div>
                          ) : (
                            <DeleteIcon
                              className="cursor-pointer"
                              onClick={() => handleDelete(item.id)}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
        <Modal show={showEditModal} centered onHide={handleEditClose}>
          <Modal.Body>
            <WhatsNewCreate editData={editData} />
          </Modal.Body>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default WhatsNewListing;
