 export const dnsFilterOption = [
  {
    id: 0,
    name: "Signed",
    type: "DNSSEC: Signed",
    key: "4",
    active: false,
  },
  {
    id: 1,
    name: "Unsigned",
    type: "DNSSEC: Unsigned",
    key: "4",
    active: false,
  },
];
