import React, { useCallback, useEffect, useState, useMemo } from "react";
import InvitationForm from "./InvitationForm";
import { Triangle } from "react-loader-spinner";
import { Container, Image, Table, Row, Col } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroller";
import axios from "./../../../util/axios";

async function fetchInvitations(page, pageSize) {
  try {
    let res = await axios.get("invitations", {
      params: { page: page, pageSize: pageSize },
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

const fetchAllOrganization = async () => {
  try {
    let res = await axios.get("organizations", {
      params: { page: 1, pageSize: 1000 },
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const Invitations = ({ allOrganizations }) => {
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [fetching, setFetching] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [allOrg, setAllOrg] = useState([]);

  const fetchOrganizations = async () => {
    const orgData = await fetchAllOrganization();
    setAllOrg(orgData.items);
  };

  const fetchItems = useCallback(
    async (page) => {
      if (fetching) {
        return;
      }

      setFetching(true);

      try {
        const data = await fetchInvitations(page, pageSize);       
        setTotalPages(data.totalPages);
        setItems((currentItems) => [...currentItems, ...data.items]);
        setCurrentPage(page);
      } finally {
        setFetching(false);
      }
    },
    [fetching, pageSize]
  );

  useEffect(() => {
    fetchItems(1); // Load the first page on component mount
  }, []);

  useEffect(() => {
    fetchOrganizations();
  }, []);



  const loader = (
    <div key="loader" className="loader w-100 text-center">
      <Triangle
        height="80"
        width="80"
        color="#fff"
        ariaLabel="triangle-loading"
        wrapperStyle={{ justifyContent: "center" }}
        wrapperClassName=""
        visible={true}
      />
    </div>
  );

  const tableRows = useMemo(() => {
    return items.map((item, index) => {
      let organizationName = allOrg?.find(
        (el) => el.id === item.organization_id
      )?.org_name;

      return (
        <tr key={`${item.id}-${index}`}>
          <td>{item.id}</td>
          <td>{item.email || "N/A"}</td>
          <td>{organizationName || "N/A"}</td>
          <td>{item.phone || "N/A"}</td>
          <td>{item.code}</td>
          <td>{item.users}</td>
        </tr>
      );
    });
  }, [items, allOrg]);

  return (
    <React.Fragment>
      <Container className="main_container_style">
        <Row>
          <Col sm="12" lg="8">
            <h1 className="fw-normal mb-5">
              <Image src="/images/icon-invitation.png" width={50} rounded /> Invitations
            </h1>
            <div className="scroll-container">
              <InfiniteScroll
                pageStart={0}
                loadMore={() => fetchItems(currentPage + 1)}
                hasMore={currentPage < totalPages}
                loader={loader}
                useWindow={false}
              >
                <Table variant="dark" striped bordered hover responsive>
                  <thead className="sticky-top">
                    <tr>
                      <th>#</th>
                      <th>Email</th>
                      <th>Organization Name</th>
                      <th>Phone</th>
                      <th>Code</th>
                      <th>Registered Users</th>
                    </tr>
                  </thead>
                  <tbody>{tableRows}</tbody>
                </Table>
              </InfiniteScroll>
            </div>
          </Col>
          <Col sm="12" lg="4">
            <InvitationForm allOrganizations={allOrg} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Invitations;
