export const passAuthFilter = [
  {
    id: 0,
    name: "Yes",
    type: "Anonymous Authentication: Yes",
    key: "3",
    active: false,
  },
  {
    id: 1,
    name: "No",
    type: "Anonymous Authentication: No",
    key: "3",
    active: false,
  },
];

export const encryptionFilter = [
  {
    id: 0,
    name: "Yes",
    type: "Encryption: Yes",
    key: "4",
    active: false,
  },
  {
    id: 1,
    name: "No",
    type: "Encryption: No",
    key: "4",
    active: false,
  },
];
export const ftpAuthFilter = [
  {
    id: 0,
    name: "Secured",
    type: "FTP Auth. Method: Secured",
    key: "5",
    active: false,
  },
  {
    id: 1,
    name: "Unsecured",
    type: "FTP Auth. Method: Unsecured",
    key: "5",
    active: false,
  },
];
export const dictionaryAttackFilter = [
  {
    id: 0,
    name: "Yes",
    type: "Dictionary Attack: Yes",
    key: "7",
    active: false,
  },
  {
    id: 1,
    name: "No",
    type: "Dictionary Attack: No",
    key: "7",
    active: false,
  },
];