import React, { useState } from 'react';
import { Dropdown, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as Edit } from "../../assets/images/edit.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/chevron-green-down.svg";
import { useParams } from "react-router-dom";

const BaseDropdown = ({ items, selectedItem, onSelect, title, isLoading, userLevel }) => {
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(prev => !prev);
  return (
    <Dropdown onToggle={toggleDropdown} show={isOpen} className="border-0 bg-transparent2 w-100 target-dropdown-main" data-bs-theme="dark"  >
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        className="target-dropdown d-flex w-100 align-items-center justify-content-between"
        style={{ height: "50px", background: "#0A0A0E", borderColor: "#398FFE", borderRadius: "5px" }}
      >
        <div className="d-flex align-items-center">
     <div className="dp-selected-item d-flex">
  <span className={`text-truncate ${selectedItem?.default_scan !== 1 ? 'flex-grow-1' : ''}`}>
    {selectedItem && selectedItem.title}
    {!selectedItem && title}
  </span>
  {selectedItem?.default_scan === 1 && (
    <tag className="target-default">Default</tag>
  )}
  {isLoading ? (<Spinner animation="border" size="sm" />) : null}
</div>

         
        </div>
        <ArrowDown height={18} width={18} className={`base-drop-down-arrow ${isOpen ? 'open' : ''}`} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="w-100 mt-2 p-0" style={{ background: "#0A0A0E", borderColor: "#398FFE", padding: "13px 20px", maxHeight: 500, overflowY: 'auto' }}>
        {items.map((item) => (
          <Dropdown.Item
            className="target-hover"
            style={{ padding: "6px 20px", display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            as={Link}
            key={item.id}
            to={`/assets-inventory/${item.target_uuid}`}
            onClick={() => onSelect(item)}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {item.title}
              {item?.default_scan === 1 && <tag className="target-default">Default</tag>}
            </div>
            <Link
              to={`/assets-inventory/${item.target_uuid}/edit`}
              className="add-button header-title"
            >
              <Edit className="edit-icon" />
            </Link>
          </Dropdown.Item>
        ))}
        <hr className="m-0 p-0" />
        {(userLevel === 0 && items.length == 0) || userLevel === 1 || userLevel === 9 ?
        <Dropdown.Item as={Link} to="/assets-inventory/new" >
          <div className="d-flex align-items-center add-button header-title p-3 gap-2" style={{ marginLeft: '-4px' }}>
            <i className="bi bi-plus-circle "></i> <span className="ml-5 p-0 fw-lighter">Add New</span>
          </div>
        </Dropdown.Item>:null}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default BaseDropdown;
