import { Button, Modal } from "react-bootstrap";

const AssetsModal = ({
  showModal,
  handleOnHide,
  rejectAction,
  rejectIP,
  handleRejectSubmit,
}) => {
  return (
    <Modal show={showModal} centered onHide={handleOnHide} className="w-70">
      <Modal.Body className="securityissueCard_modal">
        <div
          className="securityissueCard_modal_section"
          style={{
            padding: "20px 13px 10px",
          }}
        >
          <div>
            <Modal.Title>
              <h3 className="securityissueCard_modal_header">
                {rejectAction == 1
                  ? `Are you sure you want to reject ${
                      rejectIP?.length == 1
                        ? "this Hostname"
                        : `${rejectIP?.length} Hostnames`
                    }?`
                  : `Are you sure you want to reject ${
                      rejectIP?.length == 1
                        ? "this IP"
                        : `${rejectIP?.length} IPs`
                    }?`}
              </h3>
              <div className="securityissueCard-modal_content" style={{}}>
                {rejectAction == 1
                  ? `${
                      rejectIP?.length == 1
                        ? "This Hostname"
                        : `These hostnames`
                    } will no longer be included in the asset inventory.`
                  : `The following hostnames will also be rejected and will no longer be included in the asset inventory.`}
              </div>
              {rejectAction == 2 && (
                <div>
                  {rejectIP.map((el) => (
                    <div
                      style={{
                        borderTop: "1px solid #A39C9C",
                        padding: "12px 0px 0px",
                        marginTop: "16px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "13px",
                          color: "white",
                          opacity: 0.6,
                          fontWeight: 300,
                        }}
                      >
                        Related to IP - {el.ip}
                      </div>
                      {el?.relatedHosts?.map((ol) => (
                        <div
                          style={{
                            fontWeight: 300,
                            paddingLeft: "12px",
                            marginTop: "8px",
                          }}
                        >
                          {ol}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </Modal.Title>
          </div>
          <div className="securityissueCard_modal_button">
            <Button
              className="securityissueCard_button fs-12"
              onClick={handleOnHide}
            >
              Cancel
            </Button>
            <Button
              className="securityissueCard_button fs-12"
              onClick={handleRejectSubmit}
            >
              Yes Reject
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AssetsModal;
