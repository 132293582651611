import React, { useState } from "react";
import HashActiveIcon from "../../assets/images/hash-active-icon.svg";
import HashDisableIcon from "../../assets/images/hash-disable-icon.svg";
import CrackHashIcon from "../../assets/images/crack-hash-icon.svg";

const CrackHashButton = ({ disabled = false, onCrack }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`crack-hash-button`}
      onClick={onCrack}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={
          disabled
            ? HashDisableIcon
            : isHovered
            ? HashActiveIcon
            : CrackHashIcon
        }
        alt="Crack Hash Icon"
        className="manage-icon"
      />
      <span>Crack all Hashes</span>
    </div>
  );
};

export default CrackHashButton;
