import React from "react";
import "chartjs-plugin-datalabels";
import "chart.js/auto";
import ComparisonChart from "../charts/ComparisonChart";
import NoDataIcon from "../../assets/images/no-data-found.svg";
import DataInProcessIcon from '../../assets/images/no-data.svg'
import moment from "moment";

const OffenseCard = ({ cardsData }) => {
  return (
    <React.Fragment>
      <div className="offensecard_main">
        {cardsData.image ? (
          <div className="image-card">
            <div className="offensecard_title">
              Attack: <span>{cardsData?.CardTitle || "Unknown Attack"}</span>
            </div>
            <div className="dotted-line-image"></div>
            <div className="offensecard_image_container">
              <img
                src={cardsData?.isComingSoon ? cardsData.image : cardsData?.inProcess ? DataInProcessIcon : NoDataIcon}
                alt="Card icon"
                className="offensecard_image"
              />
              <p className="coming-soon-text">
                {cardsData?.isComingSoon ? "Coming soon" : cardsData?.inProcess ? "Data in Process" : "No Data Was Found"}
              </p>
            </div>
          </div>
        ) : (
          <>
            <div className="offensecard_title">
              Attack: <span>{cardsData?.CardTitle}</span>
            </div>
            <div className="dotted-line"></div>
            <div className="offensecard_output_section">
              <div className="offensecard_output_title">Output</div>
              <div className="margindiv"></div>
              <div className="card_subsection">
                <ul
                  style={{
                    padding: "6px 24px 0px",
                  }}
                >
                  <li className="fw-semibold">
                    [+] [
                    {moment(new Date(cardsData.start_time)).format(
                      "YYYY-MM-DD hh:mm"
                    )}
                    ] Starting the Attack:
                  </li>
                  <br />
                  {cardsData?.data?.map((item) => (
                    <>
                      <li className="greentext">{item?.ip}</li>
                      <li className="greentext">{item?.passAuth}</li>
                      <li className="greentext">{item?.dictionary}</li>
                      <li className="greentext">{item?.responses}</li>
                      <li className="">Result: {item?.result}</li>
                      <br />
                    </>
                  ))}
                  <li className="fw-semibold">
                    [+] [
                    {moment(new Date(cardsData.end_time)).format(
                      "YYYY-MM-DD hh:mm"
                    )}
                    ] Attack Completed
                  </li>
                </ul>
              </div>
            </div>
            <ComparisonChart cardsData={cardsData} />
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default OffenseCard;
