import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  FormControl,
  FormGroup,
  Badge,
  Row,
} from "react-bootstrap";
import { Formik, Form, Field, FieldArray } from "formik";
import { Modal } from "react-bootstrap";
import { UpdateList } from "../../whatsNew/UpdateList";
import { ReactComponent as Xmark } from "../../../assets/images/xmark.svg";
import { urlRegex } from "../../../util/constants";

const WhatsNewCreate = ({ editData }) => {
  const [submitBtn, setSubmitBtn] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [file, setFile] = useState(null);
  const [subFiles, setSubFiles] = useState(
    editData?.subUpdates?.map((subUpdate) => subUpdate.file) || []
  );
  const badges = [
    { name: "Bugs Fix", value: "bug" },
    { name: "UI Update", value: "ui" },
    { name: "Integration Update", value: "integration" },
    { name: "New Feature", value: "feature" },
    { name: "None", value: "none" },
  ];

  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = "Please enter title.";
    }
    if (values.badges?.length === 0) {
      errors.badges = "Please select a category.";
    }
    if (values.visit_page && !values.url) {
      errors.url = "Please enter URL.";
    }
    if (values.visit_page && values.url && !urlRegex.test(values.url)) {
      errors.url = "Invalid URL format.";
    }
    if (values.subUpdates && values.subUpdates.length > 0) {
      const subUpdatesErrors = values.subUpdates.map((subUpdate) => {
        const subErrors = {};
        if (!subUpdate.title) {
          subErrors.title = "Please enter a title.";
        }
        if (
          subUpdate.visit_page &&
          subUpdate.url &&
          !urlRegex.test(subUpdate.url)
        ) {
          subErrors.url = "Invalid URL format.";
        }
        return subErrors;
      });

      const filteredSubUpdatesErrors = subUpdatesErrors.filter((error) =>
        Object.keys(error).length > 0 ? error : null
      );
      if (filteredSubUpdatesErrors.length > 0) {
        errors.subUpdates = filteredSubUpdatesErrors;
      }
    }

    return errors;
  };

  const initialValues = editData
    ? {
        title: editData.title || "",
        badges: editData.badge || [],
        url: editData.url || "",
        visit_page: editData.visit_page || false,
        file: editData.file || null,
        subUpdates: editData.subUpdates || [],
      }
    : {
        title: "",
        badges: [],
        visit_page: false,
        file: null,
        url: "",
        subUpdates: [],
      };

  const handleSubmit = (values) => {
    const formData = { ...values, file, subFiles };
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      const fileURL = URL.createObjectURL(e.target.files[0]);
      setFile(fileURL);
    }
  };

  const handleSubFileChange = (id, event) => {
    const file = event.target.files[0];
    if (file) {
      setSubFiles((prevSubFiles) => ({
        ...prevSubFiles,
        [id]: URL.createObjectURL(file),
      }));
    }
  };

  const handleSelectCategory = (event, field, setFieldValue) => {
    const selectedOption = event.target.value;
    const currentIndex = field.value.indexOf(selectedOption);
    const newValues = [...field.value];

    if (currentIndex === -1) {
      newValues.push(selectedOption);
    } else {
      newValues.splice(currentIndex, 1);
    }

    setFieldValue(field.name, newValues);
  };

  useEffect(() => {
    if (isLoading) {
      setSubmitBtn({
        disabled: true,
        label: editData ? "Updating What's New." : "Adding What's New.",
      });
    } else {
      setSubmitBtn({
        disabled: false,
        label: editData ? "Update What's New" : "Add What's New",
      });
    }
  }, [isLoading]);

  const handleCategoryChange = (event, field, setFieldValue) => {
    const selectedOption = event.target.value;
    const currentIndex = field.value.indexOf(selectedOption);
    const newValues = [...field.value];

    if (currentIndex === -1) {
      newValues.push(selectedOption);
    } else {
      newValues.splice(currentIndex, 1);
    }

    setFieldValue(field.name, newValues);
  };

  const handlePreview = (data) => {
    setShowPreview(true);
  };

  const handleRemoveBadge = (value, field, setFieldState) => {
    const newBadges = field.value.filter((badgeValue) => badgeValue !== value);
    setFieldState(field.name, newBadges);
  };

  return (
    <>
      <Container fluid className={editData ? "" : "main_container_style"}>
        <Row
          className="justify-content-start m-0"
          style={{ display: editData ? "none" : "block" }}
        >
          <Col>
            <h1 className="fw-normal mb-3">What's New</h1>
          </Col>
        </Row>
        <div className={editData ? "w-100 m-auto p-2" : "w-50 m-auto"}>
          <Row>
            <Col>
              <h2
                className={editData ? "my-3 text-center" : "my-5 text-center"}
              >
                {editData ? "Edit" : "Add"} What's New
              </h2>
              <Formik
                validate={validate}
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={handleSubmit}
                render={({ values, isValid, isSubmitting }) => {
                  return (
                    <Form className="form-height-set">
                      <Field
                        name="title"
                        render={({
                          field,
                          form: { touched, errors },
                          meta,
                        }) => (
                          <FormGroup controlId="title" className="my-3">
                            <FloatingLabel
                              controlId="floatingPassword"
                              label="Title"
                            >
                              <FormControl
                                type={"text"}
                                size="lg"
                                value={field.value}
                                onChange={field.onChange}
                                placeholder=""
                                className={
                                  meta.touched && meta.error ? "is-invalid" : ""
                                }
                                {...field}
                              />
                              {meta.touched && meta.error ? (
                                <div className="invalid-feedback">
                                  {meta.error}
                                </div>
                              ) : null}
                            </FloatingLabel>
                          </FormGroup>
                        )}
                      />

                      <Field name="badges">
                        {({
                          field,
                          form: { setFieldValue, touched, errors },
                          meta,
                        }) => (
                          <FormGroup controlId="badges" className="my-3">
                            <FloatingLabel label="Category">
                              <FormControl
                                as="select"
                                size="lg"
                                {...field}
                                value={[]}
                                onChange={(event) =>
                                  handleCategoryChange(
                                    event,
                                    field,
                                    setFieldValue
                                  )
                                }
                                className={`form-control-height ${
                                  meta.touched && meta.error ? "is-invalid" : ""
                                }`}
                              >
                                <option value="" disabled>
                                  Select Category
                                </option>
                                {badges?.map((badge) => (
                                  <option key={badge.value} value={badge.value}>
                                    {badge.name}
                                  </option>
                                ))}
                              </FormControl>
                              {meta.touched && meta.error ? (
                                <div className="invalid-feedback">
                                  {meta.error}
                                </div>
                              ) : null}
                            </FloatingLabel>

                            <div className="selected-badges">
                              {field.value?.map((value) => (
                                <>
                                  <span
                                    key={value}
                                    className="badge bg-secondary m-1"
                                  >
                                    {
                                      badges.find(
                                        (badge) => badge.value === value
                                      )?.name
                                    }
                                    <span
                                      className="px-0 pb-0 pt-0 ms-2 cursor-pointer"
                                      onClick={() => {
                                        handleRemoveBadge(
                                          value,
                                          field,
                                          setFieldValue
                                        );
                                      }}
                                    >
                                      <Xmark />
                                    </span>
                                  </span>
                                </>
                              ))}
                            </div>
                          </FormGroup>
                        )}
                      </Field>

                      {!editData && (
                        <FormGroup controlId="file" className="my-3">
                          <FormControl
                            type="file"
                            size="lg"
                            onChange={handleFileChange}
                            value={file ? undefined : ""}
                            className={file ? "is-valid" : ""}
                            accept=".jpg, .jpeg, .png, .gif, .bmp, .svg, .webp, .tiff, .tif, .ico"
                          />
                        </FormGroup>
                      )}

                      <Field name="visit_page">
                        {({
                          field,
                          form: { touched, errors, setFieldValue },
                          meta,
                        }) => (
                          <FormGroup controlId="visit_page" className="my-3">
                            <FloatingLabel label="Visit Page">
                              <FormControl
                                as="select"
                                size="lg"
                                {...field}
                                className={`form-control-height ${
                                  meta.touched && meta.error ? "is-invalid" : ""
                                }`}
                                onChange={(event) =>
                                  setFieldValue(
                                    field.name,
                                    event.target.value === "true"
                                  )
                                }
                              >
                                <option key="No" value={false}>
                                  No
                                </option>
                                <option key="Yes" value={true}>
                                  Yes
                                </option>
                              </FormControl>
                              {meta.touched && meta.error ? (
                                <div className="invalid-feedback">
                                  {meta.error}
                                </div>
                              ) : null}
                            </FloatingLabel>
                          </FormGroup>
                        )}
                      </Field>

                      {values.visit_page && (
                        <Field
                          name="url"
                          render={({
                            field,
                            form: { touched, errors },
                            meta,
                          }) => (
                            <FormGroup controlId="url" className="my-3">
                              <FloatingLabel
                                controlId="floatingPassword"
                                label="URL"
                              >
                                <FormControl
                                  type={"text"}
                                  size="lg"
                                  value={field.value}
                                  onChange={field.onChange}
                                  placeholder=""
                                  className={
                                    meta.touched && meta.error
                                      ? "is-invalid"
                                      : ""
                                  }
                                  {...field}
                                />
                                {meta.touched && meta.error ? (
                                  <div className="invalid-feedback">
                                    {meta.error}
                                  </div>
                                ) : null}
                              </FloatingLabel>
                            </FormGroup>
                          )}
                        />
                      )}

                      <FieldArray name="subUpdates">
                        {({ insert, remove, push }) => (
                          <>
                            <br />
                            {values.subUpdates.length > 0 &&
                              values.subUpdates.map((subUpdate) => {
                                const idx = values.subUpdates.findIndex(
                                  (u) => u.id === subUpdate.id
                                );

                                return (
                                  <div key={subUpdate.id}>
                                    <h3>Sub Update {subUpdate.id}</h3>
                                    <Field
                                      name={`subUpdates.${idx}.title`}
                                      render={({
                                        field,
                                        form: { touched, errors },
                                        meta,
                                      }) => (
                                        <FormGroup
                                          controlId={`title-${subUpdate.id}`}
                                          className="my-3"
                                        >
                                          <FloatingLabel label="Title">
                                            <FormControl
                                              type="text"
                                              size="lg"
                                              value={field.value}
                                              onChange={field.onChange}
                                              className={
                                                meta.touched && meta.error
                                                  ? "is-invalid"
                                                  : ""
                                              }
                                              {...field}
                                            />
                                            {meta.touched && meta.error && (
                                              <div className="invalid-feedback">
                                                {meta.error}
                                              </div>
                                            )}
                                          </FloatingLabel>
                                        </FormGroup>
                                      )}
                                    />

                                    <Field
                                      name={`subUpdates.${idx}.badges`}
                                      render={({
                                        field,
                                        form: {
                                          setFieldValue,
                                          touched,
                                          errors,
                                        },
                                        meta,
                                      }) => (
                                        <FormGroup
                                          controlId={`badges-${idx}`}
                                          className="my-3"
                                        >
                                          <FloatingLabel label="Category">
                                            <FormControl
                                              as="select"
                                              size="lg"
                                              {...field}
                                              value={[]}
                                              onChange={(event) =>
                                                handleSelectCategory(
                                                  event,
                                                  field,
                                                  setFieldValue
                                                )
                                              }
                                              className={`form-control-height ${
                                                meta.touched && meta.error
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                            >
                                              <option value="" disabled>
                                                Select Category
                                              </option>
                                              {badges?.map((badge) => (
                                                <option
                                                  key={badge.value}
                                                  value={badge.value}
                                                >
                                                  {badge.name}
                                                </option>
                                              ))}
                                            </FormControl>
                                            {meta.touched && meta.error ? (
                                              <div className="invalid-feedback">
                                                {meta.error}
                                              </div>
                                            ) : null}
                                          </FloatingLabel>
                                          <div className="selected-badges">
                                            {field.value?.map((value) => (
                                              <span
                                                key={value}
                                                className="badge bg-secondary m-1"
                                              >
                                                {
                                                  badges.find(
                                                    (badge) =>
                                                      badge.value === value
                                                  )?.name
                                                }
                                                <span
                                                  className="px-0 pb-0 pt-0 ms-2 cursor-pointer"
                                                  onClick={() => {
                                                    handleRemoveBadge(
                                                      value,
                                                      field,
                                                      setFieldValue
                                                    );
                                                  }}
                                                >
                                                  <Xmark />
                                                </span>
                                              </span>
                                            ))}
                                          </div>
                                        </FormGroup>
                                      )}
                                    />

                                    {!editData && (
                                      <FormGroup
                                        controlId={`file-sub-${subUpdate.id}`}
                                        className="my-3"
                                      >
                                        <FormControl
                                          type="file"
                                          size="lg"
                                          onChange={(e) =>
                                            handleSubFileChange(subUpdate.id, e)
                                          }
                                          className={
                                            subFiles[subUpdate.id]
                                              ? "is-valid"
                                              : ""
                                          }
                                          accept=".jpg, .jpeg, .png, .gif, .bmp, .svg, .webp, .tiff, .tif, .ico"
                                        />
                                      </FormGroup>
                                    )}

                                    <Field
                                      name={`subUpdates.${idx}.visit_page`}
                                      render={({
                                        field,
                                        form: { setFieldValue },
                                        meta,
                                      }) => (
                                        <FormGroup
                                          controlId={`visit_page-${subUpdate.id}`}
                                          className="my-3"
                                        >
                                          <FloatingLabel label="Visit Page">
                                            <FormControl
                                              as="select"
                                              size="lg"
                                              {...field}
                                              className={`form-control-height ${
                                                meta.touched && meta.error
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              onChange={(event) =>
                                                setFieldValue(
                                                  `subUpdates.${idx}.visit_page`,
                                                  event.target.value === "true"
                                                )
                                              }
                                            >
                                              <option key="No" value={false}>
                                                No
                                              </option>
                                              <option key="Yes" value={true}>
                                                Yes
                                              </option>
                                            </FormControl>
                                            {meta.touched && meta.error && (
                                              <div className="invalid-feedback">
                                                {meta.error}
                                              </div>
                                            )}
                                          </FloatingLabel>
                                        </FormGroup>
                                      )}
                                    />

                                    {subUpdate.visit_page && (
                                      <Field
                                        name={`subUpdates.${idx}.url`}
                                        render={({
                                          field,
                                          form: { touched, errors },
                                          meta,
                                        }) => (
                                          <FormGroup
                                            controlId={`url-${subUpdate.id}`}
                                            className="my-3"
                                          >
                                            <FloatingLabel label="URL">
                                              <FormControl
                                                type="text"
                                                size="lg"
                                                value={field.value}
                                                onChange={field.onChange}
                                                className={
                                                  meta.touched && meta.error
                                                    ? "is-invalid"
                                                    : ""
                                                }
                                                {...field}
                                              />
                                              {meta.touched && meta.error && (
                                                <div className="invalid-feedback">
                                                  {meta.error}
                                                </div>
                                              )}
                                            </FloatingLabel>
                                          </FormGroup>
                                        )}
                                      />
                                    )}

                                    <div style={{ textAlign: "end" }}>
                                      <button
                                        style={{
                                          backgroundColor: "transparent",
                                          outline: "none",
                                          border: "none",
                                        }}
                                        type="button"
                                        onClick={() => remove(idx)}
                                        className="add-button header-title mb-2"
                                      >
                                        <i className="bi bi-minus-circle mx-2"></i>
                                        Remove
                                      </button>
                                    </div>
                                    <br />
                                    <br />
                                  </div>
                                );
                              })}

                            <div style={{ textAlign: "end" }} className="mb-5">
                              <button
                                type="button"
                                onClick={() =>
                                  push({
                                    id: values.subUpdates.length + 1,
                                    title: "",
                                    badges: [],
                                    file: null,
                                    visit_page: false,
                                    url: "",
                                  })
                                }
                                className="add-button header-title mb-2"
                                style={{
                                  backgroundColor: "transparent",
                                  outline: "none",
                                  border: "none",
                                }}
                              >
                                <i className="bi bi-plus-circle mx-2"></i>Add
                                New Sub Update
                              </button>
                            </div>
                          </>
                        )}
                      </FieldArray>

                      <Button
                        className="mb-3"
                        disabled={
                          values.title === "" || values.badges.length < 1
                        }
                        onClick={() => {
                          handlePreview(values);
                        }}
                      >
                        Preview
                      </Button>

                      <Modal
                        show={showPreview}
                        centered
                        onHide={() => setShowPreview(false)}
                        size="xl"
                      >
                        <Modal.Body>
                          <div className="w-100">
                            <UpdateList
                              preview
                              badges={values.badges}
                              date={new Date()}
                              title={values.title}
                              subUpdates={values.subUpdates.map(
                                (subUpdate) => ({
                                  ...subUpdate,
                                  image: subFiles[subUpdate.id], // Use the file from subFiles state
                                })
                              )}
                              image={file}
                              visit_page={values.visit_page}
                              url={values.url}
                            />
                          </div>
                        </Modal.Body>
                      </Modal>

                      <Button
                        type="submit"
                        disabled={!isValid || isSubmitting || isLoading}
                      >
                        {submitBtn?.label}
                      </Button>
                    </Form>
                  );
                }}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default WhatsNewCreate;
