import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

const BarChart = ({
  barData,
  optionsData,
  marginBottom,
  isChartLabelsOnTop,
  onBarClick ,
  dynamicLabels=[]
}) => {
  const onChartClick = (event, elements) => {
    if (elements.length > 0) {
      const datasetIndex = elements[0].datasetIndex;
      const elementIndex = elements[0].index;
      if (dynamicLabels && dynamicLabels.length > 1) {
        const selectedLabel = dynamicLabels[elementIndex] || "Unknown";
        onBarClick && onBarClick(selectedLabel);
      } else {
        const label = datasetIndex === 0 ? "Yes" : "No";
        onBarClick && onBarClick(label);
      }
    }
  };
  


  return (
    <div
      className="bar-chart-container"
      style={{ marginBottom: marginBottom || "3px" }}
    >
      <div className="col-12 d-flex align-items-center justify-content-center h-100">
        <Bar
          data={barData}
          options={{
            ...optionsData,
            onClick: onChartClick,
          }}
          plugins={isChartLabelsOnTop ? [ChartDataLabels] : []}
        />
      </div>
    </div>
  );
};

export default BarChart;
