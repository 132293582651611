import React, { useState, useEffect, useCallback } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BaseTable from "../../../components/table/BaseTable";
import { ReactComponent as Upload } from "../../../assets/images/upload.svg";
import InfiniteScroll from "react-infinite-scroller";
import axios from "../../../util/axios";
import { toast } from "react-toastify";
import { Triangle } from "react-loader-spinner";


const LoginPages = () => {
  const [loginPages, setLoginPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Define the page size
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadingStatus, setUploadingStatus] = useState({});
  const [selectedId, setSelectedId] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type !== "text/csv") {
        toast.error("Please select a CSV file.");
        return;
      }
      uploadFile(file);
    }
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("csv", file);
    formData.append("target_id", selectedId);
    setUploadingStatus((prev) => ({ ...prev, [selectedId]: true }));

    try {
      const response = await axios.post(`/scans-for-login-pages`, formData, {});
      if (response) {
        toast.success(response.data.msg || "File uploaded successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "info",
        });
      }
      fetchLogins(1); // Reset to first page after upload
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setUploadingStatus((prev) => ({ ...prev, [selectedId]: false }));
    }
  };

  const handleUploadClick = () => {
    document.getElementById("fileInput").click();
  };

  const columns = [
    { Header: "Organization", accessor: "organization", isSortable: true },
    { Header: "Target", accessor: "target", isSortable: true },
    { Header: "Scan Start Time", accessor: "scanStartTime", isSortable: true },
    {
      Header: "CSV Upload",
      key: "CSV Upload",
      accessor: (row) => (
        <div className="csv-main">
          <button
            className="btn-primary btn btn-custom"
            onClick={() => {
              setSelectedId(row.id);
              handleUploadClick();
            }}
          >
            {uploadingStatus[row.id] ? (
              <>
                Uploading...{" "}
                <span
                  className="spinner-border spinner-border-sm ms-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              </>
            ) : (
              <>
                File Upload <Upload className="icon-upload file-upload" />
              </>
            )}
          </button>
          <input
            type="file"
            className="d-none"
            id="fileInput"
            accept=".csv"
            onChange={(event) => handleFileChange(event, row.id)}
          />
        </div>
      ),
    },
  ];

  const fetchLogins = useCallback(
    async (page) => {
      if (isLoading) return;
      
      setIsLoading(true);
      try {
        const { data } = await axios.get(`/scans-for-login-pages`, {
          params: { page, pageSize },
        });

        const formattedData = data.response.items.map((item) => ({
          id: item.id,
          organization: item.organization.org_name,
          target: item.scanss[0].scan_name,
          scanStartTime: item.scanss[0].services[0].service_start_time,
        }));

        setLoginPages((currentPages) => [
          ...currentPages,
          ...formattedData,
        ]);
        setTotalPages(data.response.totalPages);
        setCurrentPage(page);
      } catch (err) {
        console.error("Error fetching login pages:", err);
      } finally {
        setIsLoading(false);
      }
    },
    [isLoading]
  );

  useEffect(() => {
    fetchLogins(1); // Load the first page on component mount
  }, []);

  const handleRowSelect = () => {};

  const loader = (
    <div key="loader" className="loader w-100 text-center">
      <Triangle
        height="80"
        width="80"
        color="#fff"
        ariaLabel="triangle-loading"
        wrapperStyle={{ justifyContent: "center" }}
        wrapperClassName=""
        visible={true}
      />
    </div>
  );

  return (
    <>
      <Container fluid className="main_container_style">
        <Row className="justify-content-start m-0">
          <Col>
            <h1 className="fw-normal mb-3">Login Pages</h1>
          </Col>
        </Row>
        <div className="scroll-container">
          <InfiniteScroll
            pageStart={0}
            loadMore={() => fetchLogins(currentPage + 1)}
            hasMore={currentPage < totalPages}
            loader={loader}
            useWindow={false}
          >
            <BaseTable
              className="mt-3 mb-3"
              columns={columns}
              data={loginPages}
              selectable={true}
              onRowSelect={handleRowSelect}
              showCheckboxes={false}
              action={false}
              isPagination={false}
            />
          </InfiniteScroll>
        </div>
      </Container>
    </>
  );
};

export default LoginPages;
