import React, { useState, useEffect, useContext, useRef } from "react";
import ArmoryTestedModal from "../../components/armorytested-modal/ArmoryTestedModal";
import OffenseCard from "../../components/offense-card/OffenseCard";
import skeletonIcon from "../../assets/images/skeleton.png";
import { useParams } from "react-router-dom";
import axios from "../../util/axios";
import { ThreeCircles } from "react-loader-spinner";
import { ServicesContext } from "../../components/ServicesContext";

const Dashboard = () => {
  const routeParams = useParams();
  const { scannerServices } = useContext(ServicesContext);
  const sshIntervalRef = useRef(null);
  const ftpIntervalRef = useRef(null);
  const subdomainIntervalRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [cardData, setCardData] = useState([
    {
      CardTitle: "SSH Dictionary Attack",
      image: skeletonIcon,
    },
    {
      CardTitle: "FTP Dictionary Attack",
      image: skeletonIcon,
    },
    {
      CardTitle: "Subdomain Takeover",
      image: skeletonIcon,
    },
    {
      CardTitle: "Email Spoofing",
      image: skeletonIcon,
      isComingSoon: true,
    },
    {
      CardTitle: "Log4Shell",
      image: skeletonIcon,
      isComingSoon: true,
    },
    {
      CardTitle: "RCE Exploiter",
      image: skeletonIcon,
      isComingSoon: true,
    },
  ]);
  const [ftpStatus, setFtpStatus] = useState(null);
  const [sshStatus, setSshStatus] = useState(null);
  const [subdomainStatus, setSubdomainStatus] = useState(null);

  const processData = (response, serviceType) => {
    if (!response?.data || response?.data[serviceType]?.length === 0)
      return null;

    const dataField = response.data[serviceType];
    const successCount = response.data.groups?.creds?.true || 0;
    const failCount = response.data.groups?.creds?.false || 0;

    const data = dataField.map((item) => {
      let passAuth = "";
      let dictionary = "";
      let responses = "";
      let result = "";
      if (serviceType == "ssh") {
        if (item?.hasPassworAuth) {
          passAuth = "Password Authentication Enabled";
          dictionary = "Dictionary Attack Attempted";
        } else {
          passAuth = "Password Authentication Disabled";
          dictionary = "Dictionary Attack Not Possible";
        }
        if (item?.creds?.length) {
          result = `Attack Succeeded on ${item?.ip?.ip}`;
        } else {
          result = `Attack Failed on ${item?.ip?.ip}`;
        }
      } else if (serviceType === "ftp") {
        if (item?.is_ftp_anon) {
          passAuth = "Anonymous Authentication Enabled";
          dictionary = "Dictionary Attack Attempted";
        } else {
          passAuth = "Anonymous Authentication Disabled";
          dictionary = "Dictionary Attack Attempted";
        }
        if (item?.creds?.length) {
          result = `Attack Succeeded on ${item?.ip?.ip}`;
        } else {
          result = `Attack Failed on ${item?.ip?.ip}`;
        }
      } else if (serviceType === "subdomain") {
        passAuth = "Identifying DNS Records";
        dictionary = "Checking HTTP Status Code and Service Availability";
        responses = "Analyzing Responses";
        if (item?.result) {
          result = `Subdomain ${item?.host} is vulnerable to subdomain takeover`;
        } else {
          result = `Subdomain ${item?.host} is not vulnerable to subdomain takeover`;
        }
      }
      return {
        id: item?.id,
        ip: `${serviceType === "subdomain" ? "Subdomain" : serviceType?.replace("_", " ").toUpperCase()} Detected on ${serviceType === "subdomain" ? item?.host : item?.ip?.ip
          }`,
        passAuth,
        dictionary,
        responses,
        result,
      };
    });
    return {
      CardTitle: serviceType == "subdomain" ? "Subdomain Takeover" : `${serviceType
        ?.replace("_", " ")
        .toUpperCase()} Dictionary Attack`,
      data,
      start_time: response.data.start_end_time[0].service_start_time,
      end_time: response.data.start_end_time[0].service_end_time,
      success: successCount,
      fail: failCount,
      data1: [
        {
          value: failCount,
          color: "#FF6155",
        },
      ],
      data2: [
        {
          value: successCount,
          color: "#3DDC97",
        },
      ],
    };
  };

  const fetchSSHData = async () => {
    try {
      const response = await axios.get(`scans/${routeParams?.id}/ssh`);
      const sshData = processData(response, "ssh");
      const inProcess = sshStatus === 0 || sshStatus === 1 || sshStatus === 2;
      setCardData((prevData) => [
        sshData || {
          CardTitle: "SSH Dictionary Attack",
          image: skeletonIcon,
          inProcess: inProcess
        },
        ...prevData.slice(1),
      ]);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const fetchFTPData = async () => {
    try {
      const response = await axios.get(`scans/${routeParams?.id}/ftp`);
      const ftpData = processData(response, "ftp");
      const inProcess = ftpStatus === 0 || ftpStatus === 1 || ftpStatus === 2;

      setCardData((prevData) => [
        ...prevData.slice(0, 1),
        ftpData || {
          CardTitle: "FTP Dictionary Attack",
          image: skeletonIcon,
          inProcess: inProcess
        },
        ...prevData.slice(2),
      ]);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const fetchSubzyData = async () => {
    try {
      const subzyResponse = await axios.get(`scans/${routeParams?.id}/subzylogs`);
      const updateSubzyResponse = subzyResponse?.data?.data?.length
        ? {
          ...subzyResponse,
          data: {
            subdomain: subzyResponse?.data?.data,
            groups: {
              creds: {
                true: subzyResponse?.data?.countGroup?.success || 0,
                false: subzyResponse?.data?.countGroup?.failed || 0,
              },
            },
            start_end_time: [
              {
                service_start_time: subzyResponse?.data?.data[0].created_at,
                service_end_time:
                  subzyResponse?.data?.data[subzyResponse?.data?.data?.length - 1]
                    .created_at,
              },
            ],
          },
        }
        : null;
      const subzyData = processData(updateSubzyResponse, "subdomain");
      const inProcess = subdomainStatus === 0 || subdomainStatus === 1 || subdomainStatus === 2;

      setCardData((prevData) => [
        ...prevData.slice(0, 2),
        subzyResponse?.data?.data?.length
          ? subzyData
          : {
            CardTitle: "Subdomain Takeover",
            image: skeletonIcon,
            inProcess: inProcess
          },
        ...prevData.slice(3),
      ]);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    clearInterval(sshIntervalRef.current);

    if (sshStatus === 0) {
      setIsLoading(false);
      fetchSSHData();
    } else {
      if (sshStatus === 3 || sshStatus === -1) {
        fetchSSHData();
      } else if (sshStatus === 2 || sshStatus === 1) {
        fetchSSHData();
        sshIntervalRef.current = setInterval(fetchSSHData, 5000);
      }
    }

    return () => {
      clearInterval(sshIntervalRef.current);
    };
  }, [sshStatus, routeParams?.id]);

  useEffect(() => {
    clearInterval(ftpIntervalRef.current);

    if (ftpStatus === 0) {
      setIsLoading(false);
      fetchFTPData();
    } else {
      if (ftpStatus === 3 || ftpStatus === -1) {
        fetchFTPData();
      } else if (ftpStatus === 2 || ftpStatus === 1) {
        fetchFTPData();
        ftpIntervalRef.current = setInterval(fetchFTPData, 5000);
      }
    }

    return () => {
      clearInterval(ftpIntervalRef.current);
    };
  }, [ftpStatus, routeParams?.id]);

  useEffect(() => {
    clearInterval(subdomainIntervalRef.current);

    if (subdomainStatus === 0) {
      setIsLoading(false);
      fetchSubzyData();
    } else {
      if (subdomainStatus === 3 || subdomainStatus === -1) {
        fetchSubzyData();
      } else if (subdomainStatus === 2 || subdomainStatus === 1) {
        fetchSubzyData();
        subdomainIntervalRef.current = setInterval(fetchSubzyData, 5000);
      }
    }

    return () => {
      clearInterval(subdomainIntervalRef.current);
    };
  }, [subdomainStatus, routeParams?.id]);

  useEffect(() => {
    scannerServices?.forEach((el) => {
      if (el?.service_name === "default_credential") {
        setSshStatus(el.status);
      }
      if (el?.service_name === "ftp_credential") {
        setFtpStatus(el.status);
      }
      if (el?.service_name === "subdomain_takeover") {
        setSubdomainStatus(el.status);
      }
    });
  }, [scannerServices]);

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      ) : (
        <div>
          <ArmoryTestedModal />
          <div className="d-flex flex-wrap align-items-center justify-content-center gap-4 mb-5">
            {cardData.map((card, index) => (
              <OffenseCard key={index} cardsData={card} />
            ))}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Dashboard;