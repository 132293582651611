import React, {
  useState,
  useEffect,
  useMemo,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  Table,
  Popover,
  OverlayTrigger,
  Spinner,
  Badge,
} from "react-bootstrap";
import { ReactComponent as Elipses } from "../../assets/images/elipses.svg";
import { ReactComponent as EmptyBadgeLine } from "../../assets/images/empty-badge-line.svg";
import WebPortsTemplate from "./WebPortsTemplate.js";
import ActionPopover from "../actions/ActionPopover.jsx"; // Ensure this import matches the file location
import DecisionToggle from "../badge/DecisionToggle.js";
// import { SortUp, SortDown, ArrowDownUp } from "react-bootstrap-icons";
import { ReactComponent as ChevronDown } from "../../assets/images/chevrondown.svg";
import { ReactComponent as ChevronUp } from "../../assets/images/chevronup.svg";
import { ReactComponent as SortNotSelected } from "../../assets/images//SortNotSelected.svg";
import { ReactComponent as SortDown } from "../../assets/images/SortDown.svg";
import { ReactComponent as Firstarrow } from "../../assets/images/Firstarrow.svg";
import { ReactComponent as Lastarrow } from "../../assets/images/Lastarrow.svg";
import { ReactComponent as Prevarrow } from "../../assets/images/Prevarrow.svg";
import { ReactComponent as Nextarrow } from "../../assets/images/Nextarrow.svg";
import { ReactComponent as Downpagination } from "../../assets/images/downpagination.svg";

import { ReactComponent as SortUp } from "../../assets/images/SortUp.svg";
import Dropdown from "react-bootstrap/Dropdown";
import BasePagination from "./BasePagination.js";

const GenericTable = forwardRef(
  (
    {
      columns,
      data,
      showCheckboxes,
      onRowSelect,
      action,
      actions,
      loading,
      onApplyActions,
      isDatainProcess,
      isPagination = true,
      // perPageRecords = 15,
      containerClassName = "base-table-container",
      expandedColumns,
      withoutHostIps,
      keyIndex,
      rowExpand,
      stickyAction,
      allSelectedRows
    },
    ref
  ) => {
    const [selectedRows, setSelectedRows] = useState({});
    const [hoveredColumn, setHoveredColumn] = useState(null);
    const [sortConfig, setSortConfig] = useState({
      key: null,
      direction: "asc",
    });
    const [selectedActions, setSelectedActions] = useState([]);
    const [activePopover, setActivePopover] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setrRecordsPerPage] = useState(10);
    const [showAllIds, setShowAllIds] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);

    const [collapsedState, setCollapsedState] = useState({
      withoutHostIps: true,
    });
    useImperativeHandle(ref, () => ({
      handleRowExpand,
    }));
    useEffect(() => {
      const totalPages = Math.ceil(data.length / recordsPerPage);
      if (currentPage > totalPages) {
        setCurrentPage(1);
      }
    }, [data, currentPage]);

    useEffect(() => {
      setSelectedRows(allSelectedRows || {})
    }, [allSelectedRows])

    const handleRowSelect = (rowId, isChecked) => {
      const updatedSelectedRows = { ...selectedRows, [rowId]: isChecked };
      setSelectedRows(updatedSelectedRows);

      if (onRowSelect) {
        onRowSelect(updatedSelectedRows);
      }
    };

    const handleSelectAll = (isChecked) => {
      const updatedSelectedRows = {};
      data.forEach((row) => {
        updatedSelectedRows[row.id] = isChecked;
      });
      setSelectedRows(updatedSelectedRows);

      if (onRowSelect) {
        onRowSelect(updatedSelectedRows);
      }
    };
    const handleSort = (key) => {
      if (sortConfig.key === key) {
        if (sortConfig.direction === "asc") {
          setSortConfig({ key, direction: "desc" });
        } else if (sortConfig.direction === "desc") {
          setSortConfig({ key: null, direction: "asc" });
        } else {
          setSortConfig({ key, direction: "asc" });
        }
      } else {
        setSortConfig({ key, direction: "asc" });
      }
    };
    const sortedData = useMemo(() => {
      if (!sortConfig.key) return data;
      return [...data].sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];
        if (aValue == null) return sortConfig.direction === "asc" ? 1 : -1;
        if (bValue == null) return sortConfig.direction === "asc" ? -1 : 1;

        if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      });
    }, [data, sortConfig]);

    const handleActionChange = (rowId, actionId) => {
      const currentActions = selectedActions[rowId] || [];
      const newActions = currentActions.includes(actionId)
        ? currentActions.filter((id) => id !== actionId)
        : [...currentActions, actionId];
      setSelectedActions({ ...selectedActions, [rowId]: newActions });
    };
    const applyActions = (rowId, action) => {
      const selectedRow =
        data?.find((row) => row.id === rowId) ||
        withoutHostIps?.find((row) => row.id === rowId);
      if (selectedRow) {
        setActivePopover(null);
        const selectedActionsForRow = selectedActions[rowId] || [];
        onApplyActions({
          id: rowId,
          isCheck: true,
          rejects: selectedActionsForRow,
          data: selectedRow,
          selectedAction: action,
        });
      }
    };
    const handleRowExpand = (rowId) => {
      setExpandedRows((prev) =>
        prev.includes(rowId)
          ? prev.filter((id) => id !== rowId)
          : [...prev, rowId]
      );
    };
    const renderCellData = (row, accessor) => {
      if (typeof accessor === "function") {
        return accessor(row);
      }

      const onClick = () => {
        if (showAllIds?.includes(row.id)) {
          let updatedShowAllIds = showAllIds?.filter((el) => el != row.id);
          setShowAllIds(updatedShowAllIds);
        } else {
          setShowAllIds([...showAllIds, row.id]);
        }
      };

      const accessors = accessor.split(".");
      let cellData = row;

      accessors.forEach((acc) => {
        cellData = cellData ? cellData[acc] : null;
      });
      if (
        accessor === "has_waf" ||
        (accessor === "hosting_provider" &&
          (cellData === true || cellData === false))
      ) {
        return (
          <DecisionToggle
            label={cellData === true ? "Yes" : "No"}
            isIndication={cellData}
          />
        );
      }

      if (
        accessor === "has_cdn" ||
        (accessor === "has_cms" && (cellData === true || cellData === false))
      ) {
        return <div>{cellData === true ? "Yes" : "No"}</div>;
      }
      if (accessor === "web_ports") {
        return (
          <WebPortsTemplate
            showAllIds={showAllIds}
            rowData={row}
            cellData={cellData}
            hosts={row.scan_hosts}
          />
        );
      }
      if (accessor === "scan_hosts") {
        if (row.scan_hosts.length === 0) {
          return (
            <Badge className="secondary-badges rounded m-2 px-3">
              <EmptyBadgeLine />
            </Badge>
          );
        }
        return (
          <div>
            {cellData
              .slice(0, showAllIds?.includes(row.id) ? cellData.length : 4)
              .map((item, index) => (
                <div key={index} className="my-2">
                  <Badge className="secondary-badges rounded">
                    {item.host.host}
                  </Badge>
                </div>
              ))}
            {cellData.length > 4 && (
              <a
                href="javascript:void(0)"
                className="cursor-pointer"
                style={{ color: "#398FFE" }}
                onClick={onClick}
              >
                {showAllIds?.includes(row.id) ? "Show Less..." : "Show More..."}
              </a>
            )}
          </div>
        );
      }
      return cellData !== null && cellData !== undefined && cellData !== ""
        ? cellData
        : "-";
    };
    const actionPopover = (rowId, row) => (
      <Popover id={`popover-basic-${rowId}`} className="p-0">
        <Popover.Body className="p-0">
          <ActionPopover
            rowId={rowId}
            actions={actions}
            selectedActions={selectedActions}
            onActionChange={handleActionChange}
            onApply={applyActions}
          />
        </Popover.Body>
      </Popover>
    );

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    const indexOfFirstRecord = (currentPage - 1) * recordsPerPage;
    const currentRecords = sortedData.slice(
      indexOfFirstRecord,
      indexOfFirstRecord + recordsPerPage
    );
    const totalPages = Math.ceil(sortedData.length / recordsPerPage);
    if (loading) {
      return (
        <div className="text-center">
          <Spinner animation="border" size="sm" />
        </div>
      );
    }
    const handelPerPage = (data) => {
      setrRecordsPerPage(data);
    };



    return (
      <>
        <div className={containerClassName}>
          <div className="sub-table-container">
            <Table hover variant="dark" className="custom-table" responsive>
              <thead>
                <tr>
                  {showCheckboxes && (
                    <th className="py-3">
                      <input
                        type="checkbox"
                        checked={
                          data.length > 0 &&
                          data.every((row) => selectedRows[row.id])
                        }
                        onChange={(e) => handleSelectAll(e.target.checked)}
                        className="custom-checkbox"
                      />
                    </th>
                  )}

                  {columns.map((col) => (
                    <th
                      key={col.accessor}
                      onClick={() => {
                        if (col.isSortable) {
                          handleSort(col.key ? col.key : col.accessor)
                        }
                      }}
                      onMouseEnter={() =>
                        setHoveredColumn(col.key ? col.key : col.accessor)
                      }
                      onMouseLeave={() => setHoveredColumn(null)}
                      style={{
                        cursor: col.isSortable ? "pointer" : "default",
                        width: `${col?.width}%`,
                      }}
                    >
                      <div className="d-flex align-items-center w-mx-content">
                        <span className="me-1 fw-bolder">{col.Header}</span>

                        {col.isSortable && (
                          <div className="d-flex" style={{ width: "11px" }}>
                            {sortConfig.key === (col.key || col.accessor) ? (
                              sortConfig.direction === "desc" ? (
                                <SortDown />
                              ) : sortConfig.direction === "asc" ? (
                                <SortUp />
                              ) : null
                            ) : (
                              hoveredColumn === (col.key || col.accessor) && (
                                <SortNotSelected />
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </th>
                  ))}

                  {action && <th></th>}
                </tr>
              </thead>
              <tbody>
                {currentRecords.length === 0 ? (
                  <tr className="text-center">
                    <td
                      colSpan={
                        columns.length +
                        (showCheckboxes ? 1 : 0) +
                        (action ? 1 : 0)
                      }
                    >
                      {isDatainProcess
                        ? "Data in Process"
                        : "No Data Was Found"}
                    </td>
                  </tr>
                ) : (
                  currentRecords.map((row) => (
                    <>
                      <tr
                        key={row.id}
                        style={{
                          backgroundColor: selectedRows[row.id] ? "" : "",
                        }}
                      >
                        {showCheckboxes && (
                          <td>
                            <input
                              type="checkbox"
                              checked={!!selectedRows[row.id]}
                              onChange={(e) => {
                                e.stopPropagation();
                                handleRowSelect(row.id, e.target.checked);
                              }}
                              className="custom-checkbox"
                            />
                          </td>
                        )}

                        {columns.map((col) => (
                          <td key={`${row.id}-${col.accessor}`}>
                            {renderCellData(row, col.accessor)}
                          </td>
                        ))}

                        {action && (
                          <>
                            <td className={stickyAction ? `sticky-action` : ""}>
                              <OverlayTrigger
                                trigger="click"
                                placement="left"
                                overlay={actionPopover(row.id, row)}
                                show={activePopover === row.id}
                                onToggle={() =>
                                  setActivePopover(
                                    activePopover === row.id ? null : row.id
                                  )
                                }
                                rootClose
                              >
                                <Elipses className="cursor-pointer" />
                              </OverlayTrigger>
                            </td>
                          </>
                        )}
                      </tr >
                      {
                        expandedRows.includes(row.id) && expandedColumns && (
                          <tr key={`${row.id}-expanded`}>
                            <td
                              colSpan={
                                columns.length +
                                (showCheckboxes ? 1 : 0) +
                                (action ? 1 : 0)
                              }
                              id="no-padding"
                            >
                              <div
                                className="py-5"
                                style={{ background: "#2E2E2F" }}
                              >
                                <div className="expandable-content d-flex justify-content-between px-5 ">
                                  {expandedColumns.map((col) => (
                                    <div
                                      key={col.accessor}
                                      className="expandable-item d-grid"
                                      style={{
                                        width: `${col?.width}%`,
                                      }}
                                    >
                                      <span className="expand-header-text">
                                        {col.Header}{" "}
                                      </span>
                                      <div className="mt-2">
                                        {renderCellData(row, col.accessor)}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                      }
                    </>
                  ))
                )}
              </tbody>
            </Table>
            {withoutHostIps && withoutHostIps.length > 0 && (
              <div
                className="domain-container domain-list-table"
                style={{ padding: "0px 5px" }}
              >
                <div
                  className="left-domain-container"
                  onClick={() =>
                    setCollapsedState((prev) => ({
                      ...prev,
                      withoutHostIps: !prev.withoutHostIps,
                    }))
                  }
                >
                  {collapsedState["withoutHostIps"] ? (
                    <>
                      <ChevronUp className="cursor-pointer" />
                    </>
                  ) : (
                    <>
                      <ChevronDown className="cursor-pointer" />
                    </>
                  )}
                  <span className="expand-header-text">
                    View All IPs Without Hostnames ({withoutHostIps.length}){" "}
                  </span>
                </div>
                <div className="right-domain-container">
                  {collapsedState["withoutHostIps"] ? (
                    <div></div>
                  ) : (
                    <div className="domain-collapse-table">
                      <Table hover variant="dark" className="custom-table">
                        <thead>
                          <tr>
                            {showCheckboxes && <th className="p-0"></th>}
                            {columns.map((col) => (
                              <th
                                key={col.accessor}
                                style={{
                                  width: `${col?.width}%`,
                                }}
                                className="p-0"
                              >
                                <span className="me-1"></span>
                              </th>
                            ))}
                            {action && <th className="p-0"></th>}
                          </tr>
                        </thead>
                        <tbody>
                          {withoutHostIps.map((row) => (
                            <>
                              <tr
                                key={row.id}
                                style={{
                                  backgroundColor: selectedRows[row.id]
                                    ? ""
                                    : "",
                                }}
                              >
                                {showCheckboxes && (
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={!!selectedRows[row.id]}
                                      onChange={(e) => {
                                        e.stopPropagation();
                                        handleRowSelect(
                                          row.id,
                                          e.target.checked
                                        );
                                      }}
                                      className="custom-checkbox"
                                    />
                                  </td>
                                )}
                                {columns.map((col) => (
                                  <td key={`${row.id}-${col.accessor}`}>
                                    {renderCellData(row, col.accessor)}
                                  </td>
                                ))}
                                {action && (
                                  <>
                                    <td>
                                      <OverlayTrigger
                                        trigger="click"
                                        placement="left"
                                        overlay={actionPopover(row.id, row)}
                                        show={activePopover === row.id}
                                        onToggle={() =>
                                          setActivePopover(
                                            activePopover === row.id
                                              ? null
                                              : row.id
                                          )
                                        }
                                        rootClose
                                      >
                                        <Elipses className="cursor-pointer" />
                                      </OverlayTrigger>
                                    </td>
                                  </>
                                )}
                              </tr>
                              {expandedRows.includes(row.id) &&
                                expandedColumns && (
                                  <tr key={`${row.id}-expanded`}>
                                    <td
                                      colSpan={
                                        columns.length +
                                        (showCheckboxes ? 1 : 0) +
                                        (action ? 1 : 0)
                                      }
                                      id="no-padding"
                                    >
                                      <div
                                        className="py-5"
                                        style={{ background: "#2E2E2F" }}
                                      >
                                        <div className="expandable-content d-flex justify-content-between px-5">
                                          {expandedColumns.map((col) => (
                                            <div
                                              key={col.accessor}
                                              className="expandable-item d-grid"
                                              style={{
                                                width: `${col?.width}%`,
                                              }}
                                            >
                                              <span className="expand-header-text">
                                                {col.Header}{" "}
                                              </span>
                                              <div className="mt-2">
                                                {renderCellData(
                                                  row,
                                                  col.accessor,
                                                  keyIndex
                                                )}
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                            </>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          {data.length > 0 && isPagination && (
            <>
              <hr />
              <BasePagination
                currentPage={currentPage}
                totalPages={totalPages}
                recordsPerPage={recordsPerPage}
                dataLength={data.length}
                handlePageChange={handlePageChange}
                handelPerPage={handelPerPage}
              />
            </>
          )}
        </div >
      </>
    );
  }
);

export default GenericTable;
