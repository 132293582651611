import React, { useEffect, useState } from "react";
import { Formik, Form, Field, useFormikContext } from "formik";
import * as Yup from "yup";
import {
  Container,
  FormGroup,
  FloatingLabel,
  FormControl,
  Row,
  Col,
  Button,
  Stack,
} from "react-bootstrap";
import axios from "../../util/axios";
import { toast } from "react-toastify";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import envlopStar from "../../assets/images/envolp-start.png";
import { sendEvent } from "../../util/analytics";
import { useLocation } from "react-router-dom";
import { locationToPageName } from "../../helpers";

const schema = Yup.object().shape({
  full_name: Yup.string()
    .required("Full Name is required")
    .test(
      "is-valid-title",
      "Full name cannot contain symbols",
      (value) => !/[`!@$%^&*#?~,.<>;':"/[\]|{}()=_+-]/.test(value)
    )
    .test(
      "not-only-spaces",
      "Full Name must contain non-space characters",
      (value) => value && value.trim().length > 0
    ),
  email: Yup.string().email().required("Email Address is required"),
  subject: Yup.string()
    .required("Subject is required")
    .test(
      "not-only-spaces",
      "Subject must contain non-space characters",
      (value) => value && value.trim().length > 0
    ),
  message: Yup.string()
    .required("Message is required")
    .test(
      "not-only-spaces",
      "Message must contain non-space characters",
      (value) => value && value.trim().length > 0
    ),
});

const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  let API_ENDPOINT_NODE = "/contact";
  const [sendingBtn, setSendingBtn] = useState({});
  const location = useLocation();

  useEffect(() => {
    if (isLoading) {
      setSendingBtn({ disabled: true, label: "Submitting" });
    } else {
      setSendingBtn({ disabled: false, label: "Submit" });
    }
  }, [isLoading]);

  const getUser = () => {
    const userData = localStorage.getItem("user");
    return userData ? JSON.parse(userData) : {};
  };

  const initialValues = {
    message: "",
    full_name: "",
    email: getUser()?.email || "",
    subject: "",
  };

  const handleSubmit = (values, helpers) => {
    setIsLoading(true);
    axios
      .post(API_ENDPOINT_NODE, values)
      .then((response) => {
        const data = response.data;
        setIsLoading(false);
        setIsSent(true);
        const pageName = locationToPageName(location);
        sendEvent("buttonClick", { pageName, result: "contact us" });
        if (data.message) {
          toast.success(data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
        helpers.resetForm({
          values: {
            message: "",
          },
        });
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="main_container_style">
        <div className="contact-us-main">
          {!isSent ? (
            <div className="contact-us-container">
              <div className="contact-us-header">
                <Logo />
                <span>contact us</span>
                <div>
                  We're here to help! Fill out the form below and we'll get back
                  to you as soon as possible
                </div>
              </div>
              <div className="contact-us-form">
                <Formik
                  validationSchema={schema}
                  onSubmit={handleSubmit}
                  initialValues={initialValues}
                  render={({
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    values,
                    errors,
                    validateForm,
                  }) => (
                    <Form>
                      <Field
                        name="full_name"
                        render={({
                          field,
                          form: { touched, errors },
                          meta,
                        }) => (
                          <FormGroup controlId="full_name">
                            <label>Full name</label>
                            <FormControl
                              type={"text"}
                              size="lg"
                              value={field.value}
                              onChange={field.onChange}
                              placeholder="Enter your name"
                              className={
                                meta.touched && meta.error ? "is-invalid" : ""
                              }
                              {...field}
                            />
                            {meta.touched && meta.error ? (
                              <div className="invalid-feedback">
                                {meta.error}
                              </div>
                            ) : null}
                          </FormGroup>
                        )}
                      />

                      <Field
                        name="subject"
                        render={({
                          field,
                          form: { touched, errors },
                          meta,
                        }) => (
                          <FormGroup controlId="subject">
                            <label>Subject</label>
                            <FormControl
                              type={"text"}
                              size="lg"
                              value={field.value}
                              onChange={field.onChange}
                              placeholder="Type here"
                              className={
                                meta.touched && meta.error ? "is-invalid" : ""
                              }
                              {...field}
                            />
                            {meta.touched && meta.error ? (
                              <div className="invalid-feedback">
                                {meta.error}
                              </div>
                            ) : null}
                          </FormGroup>
                        )}
                      />
                      <Field
                        name="message"
                        render={({
                          field,
                          form: { touched, errors },
                          meta,
                        }) => (
                          <FormGroup controlId="message">
                            <label>Content</label>
                            <FormControl
                              type={"text"}
                              as="textarea"
                              size="lg"
                              value={field.value}
                              onChange={field.onChange}
                              placeholder="Type here"
                              className={
                                meta.touched && meta.error ? "is-invalid" : ""
                              }
                              rows={5}
                              {...field}
                            />
                            {meta.touched && meta.error ? (
                              <div className="invalid-feedback">
                                {meta.error}
                              </div>
                            ) : null}
                          </FormGroup>
                        )}
                      />
                      <div className="my-3 d-flex justify-content-end mt-5">
                        <Button
                          type="submit"
                          className="btn btn-primary btn-full"
                          id="singup-btn"
                          disabled={sendingBtn.disabled || isLoading}
                        >
                          {sendingBtn.label}
                        </Button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          ) : (
            <div className="contact-us-sent-container">
              <div className="contact-us-header">
                <Logo />
                <img
                  src={envlopStar}
                  alt="contact-us"
                  className="contact-submit-logo my-4"
                />

                <span>Thank you for reaching out! </span>
                <div>
                  We've received your message and will respond within 24-48
                  hours
                </div>
              </div>
              <div className="my-3 d-flex justify-content-end mt-4">
                <Button
                  onClick={() => setIsSent(false)}
                  className="btn btn-primary btn-full mt-2"
                >
                  Add Another Query
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactUs;
