import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import vertical_line from "../../assets/images/statsVerticalLine.png";
import { useParams,useNavigate } from "react-router-dom";

const AssetsScanStats = ({ stats }) => {
    const { id } = useParams();
      const navigate = useNavigate();
    const [prevStats, setPrevStats] = useState({
        domains: 0,
        ips: 0,
        hosts: 0,
        totalIssues:0
    });
    const [currentStats, setCurrentStats] = useState(stats);
    const [initialLoad, setInitialLoad] = useState(true);
    const [key, setKey] = useState(0);

    useEffect(() => {
        // setPrevStats({ domains: 0, ips: 0, hosts: 0 });
        // setCurrentStats(stats);
   
            setInitialLoad(false);
            setKey(prevKey => prevKey + 1);
     
    }, [id]);

    useEffect(() => {
        if (!initialLoad) {
            setPrevStats(currentStats);
            setCurrentStats(stats);
            setKey(prevKey => prevKey + 1);
        }
    }, [stats]);

    const renderCount = (currentValue, previousValue) => {
        return (
            <CountUp
                key={key} // Use key to force re-render
                start={previousValue}
                end={currentValue}
                duration={3}
                preserveValue
            />
        );
    };

    return (
        <div className="section-main">
            <div className="section_2">
                <div>
                    <div className="content_1">
                        <div className="content_1_value">
                            {renderCount(currentStats.domains, prevStats.domains)}
                        </div>
                    </div>
                    <div className="content_2 position-relative">
                        <div className='stats-heading-text'>Domains</div>
                        <div className='position-absolute' style={{ top: '4px' }}>
                            <img src={vertical_line} alt="vertical line" />
                        </div>
                    </div>
                    <div className="content_1 p-2">
                        <div className="content_1_value">
                            {renderCount(currentStats.ips, prevStats.ips)}
                        </div>
                    </div>
                    <div className="content_2 position-relative">
                        <div className='stats-heading-text'>IPs</div>
                        <div className='position-absolute' style={{ top: '4px' }}>
                            <img src={vertical_line} alt="vertical line" />
                        </div>
                    </div>
                    <div className="content_1 p-2">
                        <div className="content_1_value">
                            {renderCount(currentStats.hosts, prevStats.hosts)}
                        </div>
                    </div>
                    <div className="content_2 position-relative">
                        <div className='stats-heading-text'>Hostnames</div>
                        <div className='position-absolute' style={{ top: '4px' }}>
                            <img src={vertical_line} alt="vertical line" />
                        </div>
                    </div>
                    <div className="content_1 p-2 cursor-pointer" onClick={() => navigate(`/security-issues/${id}`)}>
                        <div className="content_1_value text-danger">
                            {renderCount(currentStats.totalIssues, prevStats.totalIssues)}
                        </div>
                    </div>
                    <div className="content_2 position-relative" >
                        <div className='stats-heading-text'>Issues</div>
                        <div className='position-absolute' style={{ top: '4px' }}>
                          
                        </div>
                    </div>
                </div>
            </div>
            <div className="gradient"></div>
        </div>
    );
};

export default AssetsScanStats;
