import React, { useEffect, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';

const DoughnutChart = ({ data, options, title, link, linkText, height, width, onHandleClick = () => { } }) => {
    const [maxLabelWidth, setMaxLabelWidth] = useState(0);
    const labelRefs = useRef([]);
    const chartRef = useRef(null);

    useEffect(() => {
        const calculateMaxLabelWidth = () => {
            const widths = labelRefs.current.map(ref => ref?.offsetWidth || 0);
            setMaxLabelWidth(Math.max(...widths));
        };
        setTimeout(calculateMaxLabelWidth, 0);
    }, [data.labels]);

    const handleClick = (event) => {
        const chartInstance = chartRef.current;
        const activePoints = chartInstance.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);

        if (activePoints.length > 0) {
            const firstPoint = activePoints[0];
            const label = data.labels[firstPoint.index];
            const value = data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
            onHandleClick(label);
        }
    };

    return (
        <div className='chart-container' style={{ padding: 0 }}>
            <div>
                <div className='text-start'>{title}</div>
                <div className="chart-link">
                    <a href={link} className="chart-link"><u>{linkText}</u></a>
                </div>
            </div>
            <div className='chart-and-legend'>
                <div className='chart-legend-width'>
                    <div className='canvas-doughnut-graph ml-1 mr-5 '>
                        <Doughnut ref={chartRef} data={data} options={options} height={height} width={width} onClick={handleClick} />
                    </div>
                </div>
                <div className='chart-legend-set'>
                    {data.labels.map((label, index) => (
                        <div key={index} className='legend-item' style={{ width: 'fit-content' }}>
                            <span className='legend-span'>
                                <span className='legend-box' style={{ background: data.datasets[0].backgroundColor[index] }}></span>
                                <span
                                    ref={el => labelRefs.current[index] = el}
                                    className='legend-box-label cursor-pointer underline'
                                    style={{ minWidth: `${maxLabelWidth}px` }}
                                    onClick={() => onHandleClick(label)}
                                >
                                    {label}
                                </span>
                            </span>
                            <span
                                className='legend-box-value'
                                style={{ marginLeft: `${8}px` }}
                            >
                                {data.datasets[0].data[index]}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DoughnutChart;
