import React, { useState, useEffect } from "react";
import { ReactComponent as Arrow } from "../../../../assets/images/port-arrow.svg";
import DecisionToggle from "../../../../components/badge/DecisionToggle.js";
import { ThreeCircles } from "react-loader-spinner";
import moment from "moment";
import CurrentStatus from "../../../../components/status/CurrentStatus.js";
import { useNavigate, useParams } from "react-router-dom";


const PortsList = ({ port, ipStatus }) => {
  const [isCollapsed, setIsCollapsed] = useState(ipStatus == -1 ? true : false);
  const navigate = useNavigate();
  const routeParams = useParams();

  const toggleCollapse = () => {
    if (ipStatus == -1) {
      return false;
    }
    setIsCollapsed((prevState) => !prevState);
  };
  const SecurityIssue = ({ ip }, { is_encrypted }) => {
    if (!is_encrypted) {
      navigate(`/security-issues/${routeParams?.target_id}?ip=${ip}`);
    }
  };
  return (
    <div className="ports-list-table">
      <div
        className="left-ports-container"
        onClick={toggleCollapse}
        style={{ width: isCollapsed ? "auto" : "170px" }}
      >
        {ipStatus == -1 ? (
          ""
        ) : (
          <Arrow
            className={isCollapsed ? "arrow-rotated-up" : "arrow-rotated-down"}
          />
        )}
        <span>{port?.ip}</span>
      </div>
      <div
        className="right-ports-container"
        style={{ width: isCollapsed ? "auto" : "calc(100% - 170px)" }}
      >
        {isCollapsed ? (
          <div className="total-ports-details">
            <div className="divider" />
            <div className="detail-text">
              {ipStatus == -1 ? (
                "Port details are masked to prevent identification of network services"
              ) : (
                <>
                  <span>{port?.scan_ports?.length}</span> Ports, Services and
                  Versions were found
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="port-collapse-table">
            {ipStatus == -1 ? (
              ""
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>
                      {port?.is_processed || port?.scan_ports?.length > 0 ? (
                        "Port"
                      ) : (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <ThreeCircles height={18} width={18} color="#fff" />
                          <span style={{ marginLeft: "8px" }}>Scanning...</span>
                        </div>
                      )}
                    </th>
                    <th>
                      {port?.is_processed || port?.scan_ports?.length > 0 ? (
                        "Service"
                      ) : (
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                        ></div>
                      )}
                    </th>

                    <th>
                      {port?.is_processed || port?.scan_ports?.length > 0 ? (
                        "Name & Version"
                      ) : (
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                        ></div>
                      )}
                    </th>
                    <th>
                      {port?.is_processed || port?.scan_ports?.length > 0 ? (
                        "Encrypted Protocol"
                      ) : (
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                        ></div>
                      )}
                    </th>

                    <th>
                      {port?.is_processed || port?.scan_ports?.length > 0 ? (
                        "First Detected"
                      ) : (
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                        ></div>
                      )}
                    </th>
                    <th>
                      {port?.is_processed || port?.scan_ports?.length > 0 ? (
                        "Current State"
                      ) : (
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                        ></div>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {port?.scan_ports?.map((scan, index) => {
                    return (
                      <tr
                        key={index}
                        className={
                          port?.scan_ports.length === index + 1
                            ? "border-0"
                            : "borts-table-border"
                        }
                      >
                        <td>{scan?.port.replace("port:", "") || "-"}</td>
                        <td>{scan?.service || "-"}</td>
                        <td>
                          {scan?.product || scan?.version
                            ? `${scan?.product} ${scan?.version || ""}`
                            : "-"}
                        </td>
                        <td>
                          <div className={` ${scan?.is_encrypted === true || scan?.is_encrypted > 0 ? "no-pointer" : "cursor-pointer"}`} onClick={() => {
                            SecurityIssue(port,scan)
                          }}>
                            <DecisionToggle
                              label={
                                scan?.is_encrypted === true ||
                                  scan?.is_encrypted > 0
                                  ? "Yes"
                                  : "No"
                              }
                              isIndication={scan?.is_encrypted}
                            />
                          </div>

                        </td>
                        <td>
                          {scan?.first_detected
                            ? moment(scan?.first_detected).format("DD/MM/YY")
                            : ""}
                        </td>
                        <td>
                          {scan?.change_status != null ? (
                            <CurrentStatus
                              status={scan?.change_status}
                              tooltipInfo={scan?.changes}
                              headerKeys={{
                                port: "Port",
                                service: "Service",
                                product: "Name ",
                                version: "Version",
                                is_encrypted: "Encrypted Protocol",
                                first_detected: "First Detected",
                                change_status: "Current State",
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PortsList;
