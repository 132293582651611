import React from "react";
import "./NewsBadges.scss";
import { ReactComponent as BugIcon } from "../../assets/images/whats-new-bug.svg";
import { ReactComponent as FeatureIcon } from "../../assets/images/whats-new-feature.svg";
import { ReactComponent as IntegrationIcon } from "../../assets/images/whats-new-integration.svg";
import { ReactComponent as UIIcon } from "../../assets/images/whats-new-ui.svg";

const NewsBadges = ({ category }) => {
  let icon;
  let text;
  let background;
  let textColor;

  switch (category) {
    case "bug":
      icon = <BugIcon />;
      text = "Bug Fix";
      background = "#FFF4D1";
      textColor = "#F98E14";
      break;
    case "feature":
      icon = <FeatureIcon />;
      text = "New Feature";
      background = "#FEE0ED";
      textColor = "#FF3E95";
      break;
    case "ui":
      icon = <UIIcon />;
      text = "UI Update";
      background = "#E6FFFB";
      textColor = "#006D75";
      break;
    case "integration":
      icon = <IntegrationIcon />;
      text = "Integration Update";
      background = "#FEFFE6";
      textColor = "#B89B22";
      break;
    default:
      icon = null;
      text = "New";
      background = "#D1E5FF";
      textColor = "#3E90FF";
  }

  return (
    <div className="badge-container" style={{ backgroundColor: background }}>
      {icon}
      <div className="badge-text" style={{ color: textColor }}>
        {text}
      </div>
    </div>
  );
};

export default NewsBadges;
