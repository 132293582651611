import { useState, useEffect } from "react";
import { Badge } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import Popover from "react-bootstrap/Popover";
import { ReactComponent as InfoIcon } from "../../../../assets/images/circle-info.svg";
import { ReactComponent as HashLock } from "../../../../assets/images/hash-lock-icon.svg";
import { ReactComponent as DisabledHash } from "../../../../assets/images/disbaled-hash-icon.svg";

export const AdditionalInfo = ({ info }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    let filteredData = [];
    if (info?.ip_address) {
      filteredData.push({ name: "IP", value: info?.ip_address, id: "ip" });
    }
    if (info?.phone) {
      filteredData.push({ name: "Phone", value: info?.phone, id: "phone" });
    }
    if (info?.first_name) {
      filteredData.push({
        name: "First Name",
        value: info?.first_name,
        id: "first_name",
      });
    }
    if (info?.last_name) {
      filteredData.push({
        name: "Last Name",
        value: info?.last_name,
        id: "last_name",
      });
    }
    if (info?.username) {
      filteredData.push({
        name: "Username",
        value: info?.username,
        id: "username",
      });
    }
    setData(filteredData);
  }, [info]);

  return (
    <div className="flex gap-3" style={{ width: "420px", flexWrap: "wrap" }}>
      {data.length > 0
        ? data.map((item) => (
            <Badge
              key={item.id}
              className="data-leaks-badge rounded align-items-center"
            >
              {item.name}{" "}
              <OverlayTrigger
                placement="top"
                overlay={
                  <Popover
                    id={`tooltip-${item.id}`}
                    className="custom-popover-arrow"
                  >
                    <Popover.Body className="comming-tool-body">
                      <label
                        className="comming-text-tool"
                        style={{ fontWeight: 400 }}
                      >
                        <span style={{ fontWeight: 500 }}>{item.name}:</span>{" "}
                        {item.value}
                      </label>
                    </Popover.Body>
                  </Popover>
                }
              >
                <InfoIcon className="cursor-pointer data-leaks-info-icon" />
              </OverlayTrigger>
            </Badge>
          ))
        : "-"}
    </div>
  );
};

export const PasswordDataInfo = ({ rowData }) =>
  rowData?.password ? (
    rowData?.hashed_password ? (
      rowData?.password == "Uncrackable hash" ? (
        <div>
          -
          <div
            className="mt-3 color-grey-dim flex align-items-center"
            style={{ textWrap: "nowrap" }}
          >
            Couldn't Crack
            <OverlayTrigger
              placement="top"
              overlay={
                <Popover
                  id={`tooltip-${rowData?.password}`}
                  className="custom-popover-arrow"
                >
                  <Popover.Body className="comming-tool-body">
                    <label className="comming-text-tool">
                      This hash couldn't be cracked due to its complexity
                    </label>
                  </Popover.Body>
                </Popover>
              }
            >
              <InfoIcon
                className="cursor-pointer secondary-info-icon"
                style={{ marginLeft: "8px" }}
              />
            </OverlayTrigger>
          </div>
        </div>
      ) : (
        <>
          <OverlayTrigger
            placement="top"
            overlay={
              <Popover
                id={`tooltip-${rowData?.password}`}
                className="custom-popover-arrow"
              >
                <Popover.Body className="comming-tool-body">
                  <label className="comming-text-tool">
                    {rowData?.password}
                  </label>
                </Popover.Body>
              </Popover>
            }
          >
            <div
              style={{
                maxWidth: "160px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {rowData?.password}
            </div>
          </OverlayTrigger>
          <div className="mt-3" style={{ color: "#FFAE58" }}>
            Cracked
          </div>
        </>
      )
    ) : (
      <OverlayTrigger
        placement="top"
        overlay={
          <Popover
            id={`tooltip-${rowData?.password}`}
            className="custom-popover-arrow"
          >
            <Popover.Body className="comming-tool-body">
              <label className="comming-text-tool">{rowData?.password}</label>
            </Popover.Body>
          </Popover>
        }
      >
        <div
          style={{
            maxWidth: "160px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {rowData?.password}
        </div>
      </OverlayTrigger>
    )
  ) : (
    "-"
  );

export const HashDataInfo = ({ rowData, onCrack }) =>
  rowData?.hashed_password ? (
    <>
      <OverlayTrigger
        placement="top"
        overlay={
          <Popover
            id={`tooltip-${rowData?.hashed_password}`}
            className="custom-popover-arrow"
          >
            <Popover.Body className="comming-tool-body">
              <label className="comming-text-tool">
                {rowData?.hashed_password}
              </label>
            </Popover.Body>
          </Popover>
        }
      >
        <div
          style={{
            maxWidth: "160px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {rowData?.hashed_password}
        </div>
      </OverlayTrigger>
      {rowData?.hashed_password && rowData?.password ? (
        <span className="flex gap-1 mt-3 color-grey-dim align-items-center">
          <DisabledHash />
          <div
            style={{
              fontSize: "16px",
              fontWeight: "700",
            }}
          >
            Crack The Hash
          </div>
        </span>
      ) : (
        <span
          style={{ textDecoration: "none" }}
          className="flex gap-1 mt-3 text-link cursor-pointer align-items-center"
          onClick={() => onCrack(rowData)}
        >
          <HashLock />
          <div
            style={{
              fontSize: "16px",
              fontWeight: "700",
            }}
          >
            Crack The Hash
          </div>
        </span>
      )}
    </>
  ) : (
    "-"
  );
