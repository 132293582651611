import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Share } from '../../assets/images/SHARE-APP.svg';
import { ReactComponent as ArrowRight } from '../../assets/images/ARROW-RIGHT.svg';
import { services } from '../../util/scans';

function ScannerRedirections({ scannerId }) {
  const navigate = useNavigate();
  const { id } = useParams(); 
  const [matchedService, setMatchedService] = useState(null);
  const service = useMemo(() => services.find(service => service.id === scannerId), [scannerId]);
  useEffect(() => {
    if (service && service !== matchedService) {
      setMatchedService(service);
    }
  }, [service, matchedService]);

  const handleClickOnScannerPage = () => {
    if (matchedService?.key) {
      const targetUrl = matchedService.key.replace(':target_id', id);
      navigate(targetUrl);
    }
  };
  if (!matchedService) {
    return null;
  }

  return (
    <div className='d-flex align-items-center mt-2'>
      <ArrowRight className='mx-3' />
      <div className="sub-title cursor-pointer" onClick={handleClickOnScannerPage}>
        <span className='underline'>{matchedService?.title} Scanner</span>
        <Share className='ms-2' />
      </div>
    </div>
  );
}

export default ScannerRedirections;
