import React, { useEffect, useRef, useState } from "react";
import armorytestedIconSmall from "../../assets/images/armorytestedIconSmall.png";
import dotMenuIcon from "../../assets/images/dotMenuIcon.png";
import securityissue_Point from "../../assets/images/securityissue_Point.png";
import CvesPopup from "../cves/CvesPopup";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { ReactComponent as FaExclamationCircle } from "../../assets/images/FaExclamationCircle.svg";
import { ReactComponent as InfoTag } from "../../assets/images/infoTag.svg";
import { ReactComponent as OpenCircle } from "../../assets/images/open-circle.svg";
import { ReactComponent as InfoCircle } from "../../assets/images/INFO-CIRCLE.svg";
import Moment from "react-moment";
import AssetsHost from "./AssetsHost"


const SecurityIssueCard = ({ classNameVariant = "", RiskPercentage, data, isGroup = false ,asset }) => {
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Reject");
  const [action, setAction] = useState("Reject");
  const [details, setDetails] = useState(null);
  const [showAllAssets, setShowAllAssets] = useState(false);
  const dropdownRef = useRef(null);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleOnHide = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setShowModal(false);
  };

  const mainClass = `securityissueCard_main${classNameVariant ? `_${classNameVariant}` : ""
    }`;
  const valueClass = `securityissueCard_section_3_value${classNameVariant ? `_${classNameVariant}` : ""
    }`;

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setAction(option);
    setShowDropDown(false);
    setShowModal(true);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropDown(false);
    }
  };

  useEffect(() => {
    if (showDropDown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropDown]);

  let hasGenericData = false
  data?.assets?.map((el) => {
    if (el?.generic_data1 && el?.generic_data1?.pages?.length > 0) {
      hasGenericData = true
    }
  })
  let issuesFor = ''
  if (hasGenericData) {
    if (data?.service_id == 15) {
      issuesFor = 'login'
    } else if (data?.service_id == 5) {
      issuesFor = 'certificates'
    }
  }
  const source = isGroup ? asset : data;

  return (
    <React.Fragment>
      <div className="SecurityIssueCard_Main cursor-pointer" onClick={() => {
        setDetails(data);
        handleShow();
      }}>
        {/* <div className="SecurityIssueCard_checkbox">
          <Form>
            <div onClick={(e) => e.stopPropagation()}>
              <Form.Check type={"checkbox"} />
            </div>
          </Form>
        </div> */}
        <div className={mainClass}>
          <div className="securityissueCard_section_1">
            <div className="section_1_part1">
              {!isGroup && <div className="securityissueCard_title">
                {data?.issue_name}
              </div>}

              {/* {
                data?.armory_tested ?
                  <div className="section_1_part1_subpart">
                    <img src={armorytestedIconSmall} />
                    <div className="securityissueCard_subtitle">ARMORY TESTED</div>
                  </div> : ''
              } */}
            </div>
            <div className="d-flex align-items-center w-10 py-3">
              <div className="d-flex align-items-center">
                <div className="section_1_part2 mt-0 pe-2">Asset:</div>
                {source.domain && (
                  <div className="d-flex align-items-center text-unwrap">
                    {source.domain}
                  </div>
                )}
                {source.ip && (
                  <div className="d-flex align-items-center title-3 text-unwrap text-white">
                    {source.ip}
                  </div>
                )}
               
                {source.port && (
                  <div className="d-flex align-items-center text-unwrap text-white">
                  <span className="px-1">|</span>{source.port} :
                  </div>
                )}
                {source.host && (
                  <div className="d-flex align-items-center text-unwrap ">
                    <span className="px-1"></span>
                    <AssetsHost source={source}/>
                  </div>
                )}
              </div>
              <div className="d-flex w-100 flex-wrap">
                <div className="d-flex align-items-center issue-status-type text-unwrap">
                  {source.issue_status === "OPEN" && <div className=" mt-0 ms-2 pe-1 title-text text-unwrap text-white"><OpenCircle /> Open </div>}
                  {/* <div className="value-text mt-0 d-flex align-items-center text-unwrap" >Verification Failed <InfoCircle className="ms-1"/></div> */}
                  {/* <div className="mx-1">,</div> */}
                </div>
                {!isGroup && <div className="d-flex align-items-center">
                  <div className="title-text mt-0 ps-1 pe-2 text-white">, CVE:</div>
                  <div className="cve-tag d-flex align-items-center justify-content-center">
                    <span>{data?.cve ? "Yes" : 'No'}</span>
                  </div>
                </div>}

              </div>
            </div>
            <div className="section-seen-days d-flex align-items-center">
              <div className="first-seen ">
                <span className="title-text">First Seen:</span>
                <span className="data-value-text ms-2">
                  <Moment format="MMM DD, YY" className="pe-2 text-white">
                    {data?.first_seen_at}
                  </Moment>

                </span>
              </div>
              <span className="title-text mx-2">|</span>
              <div className="">
                <span className="title-text">Days Open:</span>
                <span className="data-value-text ms-2 text-white">{data?.days_open}</span>
              </div>
            </div>
          </div>
          <div className="securityissueCard_section_2 d-flex align-items-center">
            <div className="group-categroy-tags">
              <div className="d-flex align-items-center flex-wrap">
                {/* <div className="critical-assets-tag d-flex align-items-center me-2 mb-2">
              <InfoTag/>
              <span className=" text-unwrap">Critical Assets</span>
            </div> */}
                {!isGroup && <div className="issues-assets-tag d-flex align-items-center me-2 mb-2">
                  <span className=" text-unwrap">Category:{data?.scan_category}</span>
                </div>}

                <div className="issues-assets-tag d-flex align-items-center me-2 mb-2">
                  <span className=" text-unwrap">Impact: {data?.impact}%</span>
                </div>
                <div className="issues-assets-tag d-flex align-items-center me-2 mb-2">
                  <span className=" text-unwrap">Exploitability: {data?.exploitability}%</span>
                </div>
              </div>
              <div className="d-flex align-items-center mt-2 tag-m-0">
                <div className="issues-assets-tag d-flex align-items-center">
                  <span className="ms-1">CVSS: {data?.cvss}</span>
                </div>
                <div className="issues-assets-tag d-flex align-items-center mx-2">
                  <span className="ms-1">EPSS: {data?.epss}</span>
                </div>
                <div className="issues-assets-tag d-flex align-items-center">
                  <span className="ms-1">CISA KEV: {data?.cisa_kev.charAt(0).toUpperCase() + data?.cisa_kev.slice(1)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="securityissue-section_3 d-flex align-items-center">
            <div>
              
              <div className="securityissueCard_section_3 d-flex flex-row">
                <div className="securityissueCard_section_3_text text-nowrap pe-2">Risk Score:</div>
                <div className={valueClass}>{data?.risk_score}</div>
              </div>
              <div className="section_1_part1">
                <div className="securityissueCard_title">
                </div>
                {
                  data?.armory_tested && !isGroup ?
                    <div className="section_1_part1_subpart d-flex align-items-center justify-content-center">
                      <img className="h-100" src={armorytestedIconSmall} />
                      <div className="securityissueCard_subtitle">ARMORY TESTED</div>
                    </div> : ''
                }
              </div>
            </div>

          </div>
          {/* <div
            className="securityissueCard_menu"
            onClick={(event) => {
              event.stopPropagation();
              setShowDropDown(!showDropDown);
            }}
          >
            <img className="cves-popup" src={dotMenuIcon} />
          </div> */}
          {showDropDown && (
            <div
              className="securityissueCard_dropdown"
              ref={dropdownRef}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <div
                className={`${selectedOption === "Reject"
                  ? "securityissueCard_dropdown_selected"
                  : "securityissueCard_dropdown_unselected"
                  }`}
                onClick={() => {
                  handleOptionClick("Reject");
                }}
              >
                Reject
              </div>
              <div
                className={`${selectedOption === "Solve"
                  ? "securityissueCard_dropdown_selected"
                  : "securityissueCard_dropdown_unselected"
                  }`}
                onClick={() => handleOptionClick("Solve")}
              >
                Solve
              </div>
            </div>
          )}
        </div>
      </div>
      <CvesPopup handleClose={handleClose} show={show} details={details} isGroup={isGroup} asset={asset}/>


      <Modal show={showModal} centered onHide={handleOnHide} className="w-70">
        <Modal.Body className="securityissueCard_modal">
          <div className="securityissueCard_modal_section">
            <div>
              <Modal.Title>
                <h3 className="securityissueCard_modal_header">
                  Are you sure you want to {action} Issue?
                </h3>
                <div className="securityissueCard-modal_content">
                  Text about what will happen if you {action.toLowerCase()} IP
                </div>
              </Modal.Title>
            </div>
            <div className="securityissueCard_modal_button">
              <Button
                className="securityissueCard_button fs-12"
                onClick={handleOnHide}
              >
                Cancel
              </Button>
              <Button
                className="securityissueCard_button fs-12"
                onClick={handleOnHide}
              >
                Yes {action}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default SecurityIssueCard;